export class EwsAnalyticsReponseModel {
  regionEn: string;
  regionAr: string;
  active: {
    total_notifications: number;
    alert_type_count: AlertTypeCount[];
  };
  archived: {
    total_notifications: number;
    alert_type_count: AlertTypeCount[];
  };
  expired: {
    total_notifications: number;
    alert_type_count: AlertTypeCount[];
  }
}

export class AlertTypeCount {
  color: string;
  count: number;
}


export class SearchEwsNotifcationFilterModel {
  region: number[];
  alertAction: number[];
  alertType: number[];
  status: string[];
  page:number;
  page_size:number;
  sortByAlertStatus:string;
  sortByRegion:string;
  sortByCreated:string;
  sortByStartDate:string;
  sortByEndDate:string;
  searchId:string;
  fromDate:string;
  toDate:string;
  isManned: boolean = false;

  constructor(){
    this.page_size = 5;
  }
}
