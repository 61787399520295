<div *ngIf="isDivVisible" class="deletemodalbg">
    <div class="deletemodal">
        <form [formGroup]="changePasswordForm" (keyup.enter)="onSubmit()">
            <div class="d-flex justify-content-end w-100">
                <i class="far fa-times-circle dltcross" (click)="cancel()"></i>
            </div>
            <div class="d-flex justify-content-center w-100">
                <p style="font-weight:500; font-size: 1.5em ;color: #0051AC;text-align: center;margin-bottom: 2vw;">
                    {{profilePage?.ChangePassword}}
                </p>
            </div>
            <p style="font-weight: 500; font-size: 1.3em; color: #49586C;margin-bottom: 0.3vw;width: 100%;">
                {{profilePage?.OldPassword}}
            </p>
            <div class="password-input-container">
                <input formControlName="oldPassword" style="width: 100%;" #passwordInput
                    type="{{ oldPasswords ? 'text' : 'password' }}">
                <span [ngStyle]="language === 'en' && {'right': '4%'} || language === 'ar' && {'left': '4%'}"
                    class="toggle-password" (click)="toggleOldPasswordsVisibility()">
                    <i class="fa" [ngClass]="oldPasswords ? 'fa-eye-slash' : 'fa-eye'"></i>
                </span>
            </div>
            <div *ngIf="changePasswordForm.controls['oldPassword'].errors">
                <div *ngIf="changePasswordForm.controls['oldPassword'].errors['required']" class="error">
                    {{profilePage?.CurrentPasswordisRequired}}
                </div>
            </div>
            <p style="font-weight: 500; font-size: 1.3em; color: #49586C;margin-bottom: 0.3vw;width: 100%; margin-top: 1vw;">
                {{profilePage?.NewPassword}}
            </p>
            <div class="password-input-container">
                <input formControlName="newPassword" style="width: 100%;" #passwordInput
                    type="{{ newPasswords ? 'text' : 'password' }}">
                <span [ngStyle]="language === 'en' && {'right': '4%'} || language === 'ar' && {'left': '4%'}"
                    class="toggle-password" (click)="toggleNewPasswordsVisibility()">
                    <i class="fa" [ngClass]="newPasswords ? 'fa-eye-slash' : 'fa-eye'"></i>
                </span>
            </div>
            <div *ngIf="changePasswordForm.controls['newPassword'].errors" class="error">
                {{profilePage?.NewPasswordisRequired}}
            </div>
            <p style="font-weight: 500; font-size: 1.3em; color: #49586C;margin-bottom: 0.3vw;width: 100%; margin-top: 1vw;">
                {{profilePage?.ConfirmPassword}}
            </p>
            <div class="password-input-container">
                <input (keyup)="confirmNewPasswordValidator()" formControlName="confirmNewPassword" style="width: 100%;"
                    #passwordInput type="{{ confirmPasswords ? 'text' : 'password' }}">
                <span [ngStyle]="language === 'en' && {'right': '4%'} || language === 'ar' && {'left': '4%'}"
                    class="toggle-password" (click)="toggleConfirmPasswordsVisibility()">
                    <i class="fa" [ngClass]="confirmPasswords ? 'fa-eye-slash' : 'fa-eye'"></i>
                </span>
            </div>
            <div *ngIf="changePasswordForm.controls['confirmNewPassword'].errors" class="error">
                {{profilePage?.ConfirmPasswordisRequired}}
            </div>
            <div *ngIf="!isConfirmNewPasswordMatched && changePasswordForm.controls['newPassword'].value && this.changePasswordForm.controls['confirmNewPassword'].value"
                class="error">
                {{profilePage?.ConfirmPassworddoesntMatch}}
            </div>
            <div style="display: flex; justify-content: center;gap: 2vw; margin-top: 2vw;">
                <button class="save-btn" (click)="onSubmit();" style="width: 166px;">
                    <i class="far fa-save"></i>
                    {{profilePage?.Save_btn}}
                </button>
                <button class="cancel-btn" (click)="cancel()" style="width: 166px;">
                    <i class="fas fa-times"></i>
                    {{profilePage?.Cancel}}
                </button>
            </div>
        </form>
    </div>
</div>

<!-- <div class="page-title">
    <i class="fas fa-caret-right"></i>
    <p>{{profilePage?.Userprofile}}</p>
</div> -->

<p-toolbar class="row" style="margin: inherit;">
    <div class="p-toolbar-group-left col justify-content-between">
      <div class="d-flex gap-2">
        <img src="../../../assets/Icon-awesome-user-ci-1.png" style="scale: 0.7;">
        <div class="alert-history">{{profilePage?.Profile}}</div>
      </div>
      <div class="header-btn d-flex">
        <button (click)="isEditProfile = true">
          {{profilePage?.EditProfile}}
        </button>
      </div>
    </div>
  </p-toolbar>

<div class="locationBG" style="margin-top: 3vw;">
    <form [formGroup]="userProfileForm" class="d-flex flex-column col-xl-5 col-lg-6 col-md-8 col-sm-12 col-12">
        <div class="d-flex w-100 mb-2">
            <label>{{profilePage?.Username}}<sup *ngIf="isEditProfile" style="color: red;">&nbsp;*</sup></label>
            <div class="d-flex flex-column profile-input-contain">
                <input type="text" formControlName="name" [readonly]="!isEditProfile">
            </div>
        </div>
        <div class="d-flex w-90 mb-2 justify-content-end" *ngIf="isEditProfile">
            <!-- For the 'name' field -->
            <div *ngIf="userProfileForm.get('name').hasError('required')" class="error">
                {{profilePage?.Nameisrequired}}
            </div>
            <div *ngIf="userProfileForm.get('name').hasError('maxlength')" class="error">
                {{profilePage?.Nameshouldbeatmostcharacterslong}}
            </div>
            <!-- For the 'name' field -->
        </div>
        <div class="d-flex w-100 mb-2">
            <label>{{profilePage?.iqama}}<sup *ngIf="isEditProfile" style="color: red;">&nbsp;*</sup></label>
            <div class="d-flex flex-column profile-input-contain">
                <input type="text" formControlName="iqama" [readonly]="!isEditProfile" (keypress)="onKeyPress($event)" (input)="onInput($event)">
            </div>
        </div>
        <div class="d-flex w-90 mb-2 justify-content-end" *ngIf="isEditProfile">
            <!-- For the 'iqama' field -->
            <div *ngIf="userProfileForm.get('iqama').hasError('maxlength')" class="error">
                {{profilePage?.iqamahouldbeatmostcharacterslong}}
            </div>
            <div *ngIf="userProfileForm.get('iqama').hasError('minlength')" class="error">
                {{profilePage?.iqamahouldbeatmostcharacterslong}}
            </div>
            <div *ngIf="userProfileForm.get('iqama').hasError('invalidStart')" class="error">
                {{profilePage?.invalidIqaama}}
            </div>
            <!-- For the 'iqama' field -->
        </div>
        <div class="d-flex w-100 mb-2">
            <label>{{profilePage?.PhoneNo || 'Phone No'}}<sup *ngIf="isEditProfile" style="color: red;">&nbsp;*</sup></label>
            <div class="d-flex flex-column profile-input-contain">
                <div class="input-grouping">
                    <span class="input-grouping-text">+966</span>
                    <input type="text" placeholder="xxx-xxx-xxx" formControlName="phoneno" [readonly]="!isEditProfile" (keypress)="onKeyPressPhone($event)" (input)="onInputPhone($event)" maxlength="13">
                </div>
            </div>
        </div>
        <div class="d-flex w-90 mb-2 justify-content-end" *ngIf="isEditProfile">
            <!-- For the 'phoneno' field -->
            <div *ngIf="userProfileForm.get('phoneno').hasError('maxlength')" class="error">
                {{profilePage?.phonenoshouldbeatmostcharacterslong}}
            </div>
            <div *ngIf="userProfileForm.get('phoneno').hasError('minlength')" class="error">
                {{profilePage?.phonenoshouldbeatmostcharacterslong}}
            </div>
            <div *ngIf="userProfileForm.get('phoneno').hasError('invalidStart')" class="error">
                {{profilePage?.invalidPhoneno}}
            </div>
            <!-- For the 'phoneno' field -->
        </div>
        <div class="d-flex w-100 mb-2">
            <label>{{profilePage?.Department}}<sup *ngIf="isEditProfile" style="color: red;">&nbsp;*</sup></label>
            <div class="d-flex flex-column profile-input-contain">
                <input [placeholder]="isEditProfile ? profilePage?.enterDepartment : ''" type="text" formControlName="department"
                    [readonly]="!isEditProfile">
            </div>
        </div>
        <div class="d-flex w-90 mb-2 justify-content-end" *ngIf="isEditProfile">
            <!-- For the 'department' field -->
            <div *ngIf="userProfileForm.get('department').hasError('required')" class="error">
                {{profilePage?.Departmentisrequired}}
            </div>
            <div *ngIf="userProfileForm.get('department').hasError('maxlength')" class="error">
                {{profilePage?.Departmentshouldbeatmostcharacterslong}}
            </div>
            <!-- For the 'department' field -->
        </div>
        <div class="d-flex w-100 mb-2">
            <label>{{profilePage?.Title}}<sup *ngIf="isEditProfile" style="color: red;">&nbsp;*</sup></label>
            <div class="d-flex flex-column profile-input-contain">
                <input [placeholder]="isEditProfile ? profilePage?.enterTitle : ''" type="text" formControlName="title"
                    [readonly]="!isEditProfile">
            </div>
        </div>
        <div class="d-flex w-90 mb-2 justify-content-end" *ngIf="isEditProfile">
            <!-- For the 'title' field -->
            <div *ngIf="userProfileForm.get('title').hasError('maxlength')" class="error">
                {{profilePage?.Titleshouldbeatmostcharacterslong}}
            </div>
            <!-- For the 'title' field -->
        </div>
        <div class="d-flex w-100 mb-2">
            <label class="m-0">{{profilePage?.EnableOtp}}</label>
            <!-- <div class="d-flex flex-column">
            <input class="form-check-input" type="checkbox" formControlName="isTwoFactorAuth" [readonly]="!isEditProfile">
          </div> -->
            <div class="d-flex align-items-center" style="height: 35px;">
                <input [ngStyle]="isEditProfile ? {'pointer-events': 'auto'} : {'pointer-events': 'none'}" type="checkbox" [ngClass]="language == 'en' ? 'checkboxwidth' : 'checkboxwidth-ar'" class="form-check-input" formControlName="isTwoFactorAuth">
            </div>
        </div>
        <div class="d-flex w-90 mb-2 justify-content-end" *ngIf="isEditProfile">
            <!-- For the 'title' field -->
            <div *ngIf="userProfileForm?.controls['isTwoFactorAuth']?.errors?.['required']" class="error">
                {{profilePage?.PleaseSelectAnOptionForOtp}}
            </div>
            <!-- For the 'title' field -->
        </div>
        <div class="d-flex w-100 mb-2">
            <label>{{profilePage?.Email}}<sup *ngIf="isEditProfile" style="color: red;">&nbsp;*</sup></label>
            <div class="d-flex flex-column profile-input-contain">
                <input type="email" [value]="this.user?.email" [disabled]="true" [readonly]="!isEditProfile">
            </div>
        </div>
        <div class="d-flex w-100">
            <label>{{profilePage?.Password}}<sup *ngIf="isEditProfile" style="color: red;">&nbsp;*</sup></label>
            <div class="d-flex flex-column profile-input-contain">
                <div class="password-input-container">
                    <input *ngIf="!isEditProfile" formControlName="password" placeholder="●●●●●●●●"
                        [readonly]="!isEditProfile" style="width: 100%;" #passwordInput
                        type="{{ showPasswords ? 'text' : 'password' }}">
                    <span *ngIf="isEditProfile && userProfileForm.get('password').value"
                        [ngClass]="language === 'en' ? 'righteye' : 'lefteye'" class="toggle-password"
                        (click)="toggleShowPasswordsVisibility()">
                        <i class="fa" [ngClass]="showPasswords ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </span>
                    <button (click)="openChangePasswordModal()" class="widget-btn secondary"
                        *ngIf="isEditProfile">{{profilePage?.changePassword}}</button>
                </div>
            </div>
        </div>
        <div class="d-flex w-90 mb-2 justify-content-end" *ngIf="isEditProfile">
            <div *ngIf="userProfileForm.get('password').hasError('maxlength')" class="error">
                {{profilePage?.Passwordshouldbeatmostcharacterslong}}
            </div>
        </div>
        <div class="d-flex w-100 justify-content-end" *ngIf="isEditProfile">
            <div class="prodiv4 justify-content-between" style="width: 60%;">
                <button class="save-btn" (click)="onSave();" style="width: 166px;">
                    <i class="far fa-save"></i>
                    {{profilePage?.Save_btn}}
                </button>
                <button class="cancel-btn" (click)="resetform()" style="width: 166px;">
                    <i class="fas fa-times"></i>
                    {{profilePage?.Cancel}}
                </button>
            </div>
            <div style="width: 10%;"></div>
        </div>
    </form>
</div>
