import { ChangePasswordModel, ResendInviteUserModel, ResendInviteWSOuserModel } from './../../models/change-password/change-password.model';
import { UserProfileModel } from '../../models/user-profile/user-profile.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListUserTagModelAPI, NCMClientModelAPI, NCMUserModel, UserExcelModelAPI, UsersModel } from 'src/app/models/users/users.model';
import { GlobalService } from 'src/app/shared/utilities/global';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';
import { CreateWSOUser } from 'src/app/models/package/package.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

 private baseUrl: string;
  constructor(private globalService: GlobalService, private http: HttpClient) {
    this.baseUrl = `${environmentBaseUrl.url}/api/ncm/`;
  }

  inviteUser(NCMUser:NCMUserModel){
    const url = `${this.baseUrl}admin/invite-ncm-user`
    return this.http.post(url, NCMUser).toPromise();
  }
  addNcmUser(NCMUser:NCMUserModel){
    const url = `${this.baseUrl}admin/add-ncm-user`
    return this.http.post(url, NCMUser).toPromise();
  }
  listOrganization(ncmClientModelAPI: NCMClientModelAPI){
    const url = `${this.baseUrl}organization/list-organization`;
    return this.http.post(url, ncmClientModelAPI).toPromise()
  }

  getClientsList(ncmClientModelAPI: NCMClientModelAPI){
    const url = `${this.baseUrl}admin/organization-list-users`
    return this.http.post(url, { ...ncmClientModelAPI, Authorization: this.globalService.getToken() }).toPromise()
  }

  getUsersList(ncmClientModelAPI: NCMClientModelAPI){
    const url = `${this.baseUrl}admin/list-ncm-user`
    return this.http.post(url, { ...ncmClientModelAPI, Authorization: this.globalService.getToken() }).toPromise()
  }

  editUser(user:NCMUserModel){
    const url = `${this.baseUrl}admin/edit-user`;
    return this.http.post(url, user).toPromise();
  }

  editNCMUser(user:NCMUserModel){
    const url = `${this.baseUrl}admin/edit-ncm-user`;
    return this.http.post(url, user).toPromise();
  }

  editWSOuser(clientModel:CreateWSOUser){
    const url = `${this.baseUrl}package/update-wso-subscription`
    return this.http.post(url, clientModel).toPromise();
  }

  deleteUser(userId:number){
    const url = `${this.baseUrl}admin/delete-user`
    return this.http.post(url, {id: userId, Authorization: this.globalService.getToken() }).toPromise();
  }

  deleteWSOUser(userId:number){
    const url = `${this.baseUrl}package/delete-wso-subscription`
    return this.http.post(url, {user_id: userId, Authorization: this.globalService.getToken() }).toPromise();
  }

  updateUserProfile(userProfileModel: UserProfileModel){
    const url = `${this.baseUrl}user/update-profile`;
    return this.http.post(url, userProfileModel).toPromise();
  }

  changePassword(changePasswordModel:ChangePasswordModel){
    const url = `${this.baseUrl}user/change-password`
    return this.http.post(url, changePasswordModel).toPromise()
  }

  resendInviteUser(resendInviteUserModel: ResendInviteUserModel){
    const url =  `${this.baseUrl}admin/resend-invite`
    return this.http.post(url, resendInviteUserModel).toPromise()
  }

  resendInviteWSOUser(resendInviteWSOuserModel: ResendInviteWSOuserModel){
    const url =  `${this.baseUrl}package/reinvite-wso-subscription-user`
    return this.http.post(url, resendInviteWSOuserModel).toPromise()
  }

  getUserExcel(userExcelModelAPI: UserExcelModelAPI){
    const url =  `${this.baseUrl}admin/get-users-excel`
    return this.http.post(url, userExcelModelAPI).toPromise()
  }

  listUserTag(listUserTagModelAPI: ListUserTagModelAPI){
    const url = `${this.baseUrl}admin/list-user-tag`
    return this.http.post(url, listUserTagModelAPI).toPromise()
  }

  createUserTag(tagName:string){
    const url = `${this.baseUrl}admin/create-user-tag`
    return this.http.post(url, {name: tagName}).toPromise()
  }
}
