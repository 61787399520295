import { EWSNotificationModel, ListEwsNotificationDataModel, ListEwsNotificationModel, NEWListEwsNotificationModel } from 'src/app/models/EWSnotification/EWSnotification.model';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import * as echarts from 'echarts';
import { GlobalService } from 'src/app/shared/utilities/global';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertTypeCount, EwsAnalyticsReponseModel, SearchEwsNotifcationFilterModel } from 'src/app/models/ews-analytics/ews-analytics.model';
import { FormControl } from '@angular/forms';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { debounceTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

interface AlertActionModel {
  id: number;
  nameAr: string;
  nameEn: string;
  severity: string;
}

interface AlertTypeModel {
  id: number;
  color: number;
  nameEn: string;
  nameAr: string
}

interface AlertStatusModel {
  id: number;
  nameEn: string;
  nameAr: string;
  criteria: string
}


interface RegionModel {
  id: number;
  name_en: string;
  name_ar: string;
  longitude: string;
  latitude: string;
}

export class listNotificationEmailsResponseModel {
  success: boolean;
  data: listNotificationEmail[];
  paginationVariables: PaginationVariables;

  constructor(){
    this.data = [];
  }
}

interface listNotificationEmail {
  ewsId: string
  userName: string
  Email: string
  locationName: string
  alertType: any
  alertTypeAr: any
  alertStatus: any
  alertStatusAr: any
  regionName: any
  regionNameAr: any
  governorateNames: string[]
  governorateNamesAr: string[]
  emailStatus: string
  emailStatusAr: string
}

interface Data {
  id: number
  title: number
  alertType: number
  alertTypeAr: string
  alertTypeEn: string
  fromDate: string
  toDate: string
  alertStatusID: number
  alertStatusAr: string
  alertStatusEn: string
  alertStatusCategory: string
  alertHazard: AlertHazard[]
  regionID: number
  regionAR: string
  regionEn: string
  governorates: Governorate[]
  ewsOtherLocations: any[]
  otherLocationsAr: string
  otherLocationsEn: any
  tweetID: string
  enableTwitterNotification: boolean
  enableSMSNotification: boolean
  enableEmailNotification: boolean
  alertActions: AlertAction[]
  municipalities: Municipality[]
  lastModified: string
  coordinates: string
  message: string
  file: any[]
  criteria: string
  criteriaAr: string
  created_at: string
  created_by: string
}

interface AlertHazard {
  pim_id: number
  id: number
  nameEn: string
  nameAr: string
}

interface Governorate {
  id: number
  nameEn: string
  nameAr: string
  longitude: string
  latitude: string
  municipalities: any[]
}

interface AlertAction {
  pim_id: number
  id: number
  descriptionEn: string
  descriptionAr: string
}

interface Municipality {
  id: number
  nameEn: string
  nameAr: string
  governate: number
}

interface History {
  id: number
  title: number
  alertType: number
  alertTypeAr: string
  alertTypeEn: string
  fromDate: string
  toDate: string
  alertStatusID: number
  alertStatusAr: string
  alertStatusEn: string
  alertStatusCategory: string
  alertHazard: AlertHazard2[]
  regionID: number
  regionAR: string
  regionEn: string
  governorates: Governorate2[]
  ewsOtherLocations: any[]
  otherLocationsAr: string
  otherLocationsEn: any
  tweetID: string
  enableTwitterNotification: boolean
  enableSMSNotification: boolean
  enableEmailNotification: boolean
  alertActions: AlertAction2[]
  municipalities: Municipality2[]
  lastModified: string
  coordinates: string
  message: string
  file: any[]
  criteria: string
  criteriaAr: string
  created_at: string
  created_by: string
}

interface AlertHazard2 {
  pim_id: number
  id: number
  nameEn: string
  nameAr: string
}

interface Governorate2 {
  id: number
  nameEn: string
  nameAr: string
  longitude: string
  latitude: string
  municipalities: any[]
}

interface AlertAction2 {
  pim_id: number
  id: number
  descriptionEn: string
  descriptionAr: string
}

interface Municipality2 {
  id: number
  nameEn: string
  nameAr: string
  governate: number
}

interface Yellow {
  alertStatusEn: string
  alertStatusAr: string
}

interface Orange {
  alertStatusEn: string
  alertStatusAr: string
}

interface Red {
  alertStatusEn: string
  alertStatusAr: string
}

@Component({
  selector: 'app-early-warning-history-report',
  templateUrl: './early-warning-history-report.component.html',
  styleUrls: ['./early-warning-history-report.component.scss']
})
export class EarlyWarningHistoryReportComponent implements OnInit, OnDestroy {
  private debounceSubject: Subject<any> = new Subject<any>();
  private subscriptions: Subscription = new Subscription();
  language: string;
  private date = new Date().toISOString();
  isExpanded: boolean[] = [];
  isExpanded12: boolean[] = [];
  isExpanded2: boolean[] = [];
  isExpanded21: boolean[] = [];
  isExpanded3: boolean[] = [];
  items: MenuItem[];
  first = 0;
  filters: any[];
  rows = 10;
  currentFilter: any;
  notificationList: NEWListEwsNotificationModel = new NEWListEwsNotificationModel();
  dashboardPage: any;
  automaticWeatherPage: any;
  emailList: listNotificationEmailsResponseModel = new listNotificationEmailsResponseModel();

  pageSize: number = 10;
  archivedPageSize: number = 15;
  page: number = 1;
  hpageSize: number = 5;
  hpage: number = 1;

  emailsPageSize: number = 10;
  emailPageNumber: number = 1;

  regionsChart: echarts.ECharts;
  activeNotificationSort: string;
  ewsAnalyticsResponse: EwsAnalyticsReponseModel[];
  activeAndNonActiveAlertChart: echarts.ECharts;
  allRegionAlertsChart: echarts.ECharts;
  regions: RegionModel[];

  selectedAlertRegions: string = 'Riyadh'

  selectedRegions = new FormControl<number[]>([]);
  selectedAlertActions = new FormControl<number[]>([]);
  selectedStatus = new FormControl<string[]>([]);
  selectedAlertTypes = new FormControl<number[]>([]);
  alertActions: AlertActionModel[];
  archived: boolean = false;
  statuses:{id:number; nameEn:'Archive' | 'Expired' | 'Active', value: 'archive' | 'expired' | 'active', nameAr: 'الأرشيف' | 'انتهت الصلاحية' | 'نشط'}[] = [];
  alertTypes: AlertTypeModel[];

  isArchive: boolean = false;
  archiveId: string;
  alertId: number;

  user$:Observable<any>;
  isView: boolean = false;
  fileName: string;
  detailList: History[];
  detailListData: Data;
  AlertCount: AlertTypeCount[];
  AlertStatusesByAlertTypes: { Yellow: Yellow[]; Orange: Orange[]; Red: Red[]; };
  sortByAlertStatus: boolean;
  sortByEndDate: boolean;
  sortByCreated: boolean;
  sortByStartDate: boolean;
  sortByRegion: boolean;
  searchEwsNotifcationFilterModel: SearchEwsNotifcationFilterModel = new SearchEwsNotifcationFilterModel();

  searchByAlertID: string
  filterActiveReg = new FormControl<string>('',);
  filterEWSanaly = new FormControl<string>('',);
  filterRegions: RegionModel[];
  allRegIds: number[];
  filterewsAnalyticsResponse: EwsAnalyticsReponseModel[];
  sortarray: string;

  calendar = inject(NgbCalendar);
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  HistoryTable: boolean = false;
  noChanges: boolean[] = [];

  constructor(
    private router: Router,
    private toast: ToastrService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {
    this.user$ = this.globalService.userInfo$;
  }

  ngOnInit(): void {
    this.addWindowEventListener();
    this.getCurrentLanguage();
    this.initializeFormValueChanges();
    this.getQueryParams();
    this.getAlertActions();
    this.getAlertType()

    this.debounceSubject.pipe(
      debounceTime(500)
    ).subscribe(() => {
        this.getEwsNotifications();
    });

    // setTimeout(() => {
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     // Array of table header selectors
    //     const selectors = [
    //       '.table-container table thead',
    //       '.p-datatable .p-datatable-thead',
    //       '.AddUserTable table thead',
    //       '.cssTable table thead',
    //       '.customTable thead',
    //       '.autoTable thead',
    //       '.maintable thead'
    //     ];

    //     // Loop through each selector and apply the background style if the element is found
    //     selectors.forEach(selector => {
    //       const thead = document.querySelector(selector);
    //       if (thead instanceof HTMLElement) {
    //         thead.style.background = '#0051AC';
    //       }
    //     });
    //   }
    // }, 500);
  }

  addWindowEventListener() {
    const debouncedResize = this.debounce(() => {
      if (this.archived) {
        this.createRegionsAlertsChart(this.ewsAnalyticsResponse, !this.archived ? 'active' : 'archived');
      } else {
        this.createAllRegionsChart(this.ewsAnalyticsResponse);
      }
    }, 300);

    window.addEventListener('resize', debouncedResize);
  }

  initializeFormValueChanges() {
    const debouncedGetEwsNotifications = this.debounce(() => {
      this.getEwsNotifications();
    }, 300);

    const selectedStatusSubscription$ = this.selectedStatus.valueChanges.subscribe(() => {
      this.page = 1
      debouncedGetEwsNotifications();
    });

    const selectedRegionSubscription$ = this.selectedRegions.valueChanges.subscribe(() => {
      this.page = 1
      debouncedGetEwsNotifications();
    });

    const selectedAlertActionSubscription$ = this.selectedAlertActions.valueChanges.subscribe(() => {
      this.page = 1
      debouncedGetEwsNotifications();
    });

    const selectedAlertTypeSubscription$ = this.selectedAlertTypes.valueChanges.subscribe(() => {
      this.page = 1
      debouncedGetEwsNotifications();
    });

    this.subscriptions.add(selectedStatusSubscription$);
    this.subscriptions.add(selectedRegionSubscription$);
    this.subscriptions.add(selectedAlertActionSubscription$);
    this.subscriptions.add(selectedAlertTypeSubscription$);
  }

  getCurrentLanguage(){
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.getActiveAndNonActiveEwsAnalytics();
      this.translate.use(language);
      this.translate.get("Dashboard").subscribe((res) => {
        this.dashboardPage = res;
      });
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticWeatherPage = res;
      });
    }));
  }

  getQueryParams(): void {
    const queryParamsString = decodeURIComponent(window.location.pathname);

    if (queryParamsString.includes('archived')) {
      this.archived = true;
      this.statuses = [{ id: 1, nameEn: 'Archive', value: "archive", nameAr: "الأرشيف" }];
      this.selectedStatus.setValue(['archived']);
      this.selectedStatus.disable({ onlySelf: true });
    } else {
      this.archived = false;
      this.statuses = [{ id: 2, nameEn: 'Active', value: "active", nameAr: "نشط" },
                       { id: 3, nameEn: "Expired", value: "expired", nameAr: "انتهت الصلاحية" }];
      this.selectedStatus.enable();
      this.selectedStatus.setValue(['active', 'expired']);
    }
    this.getActiveAndNonActiveEwsAnalytics();
  }

  getActiveAndNonActiveEwsAnalytics() {
    this.alertService.getEwsAnalytics()
      .then((res: EwsAnalyticsReponseModel[]) => {
        this.ewsAnalyticsResponse = res;
        this.filterewsAnalyticsResponse = this.ewsAnalyticsResponse;
        this.getRegions();
        if (this.ewsAnalyticsResponse.length > 0) {
          if (!this.archived) {
            this.createAllRegionsChart(this.ewsAnalyticsResponse);
          }
          this.createRegionsAlertsChart(this.ewsAnalyticsResponse, !this.archived ? 'active' : 'archived');
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  createAllRegionsChart(ewsAnalyticsRegionData: EwsAnalyticsReponseModel[]) {
    if (this.activeAndNonActiveAlertChart) {
      this.activeAndNonActiveAlertChart.clear()
      this.activeAndNonActiveAlertChart.dispose();
    }
    const chartData = { regionEN: [], regionAr: [], expiredAlerts: [], activeAlerts: [], active: '', expired: '' };
    chartData.regionEN = ewsAnalyticsRegionData?.map((analytics) => analytics.regionEn);
    chartData.regionAr = ewsAnalyticsRegionData?.map((analytics) => analytics.regionAr);
    chartData.activeAlerts = ewsAnalyticsRegionData?.map((analytics) => analytics.active.total_notifications);
    chartData.expiredAlerts = ewsAnalyticsRegionData?.map((analytics) => analytics.expired.total_notifications);
    chartData.active = this.language === 'en' ? 'Active' : 'نشيط';
    chartData.expired = this.language === 'en' ? 'Expired' : 'منتهي الصلاحية';
    var chartDom = document.getElementById('all-regions-chart');
    this.activeAndNonActiveAlertChart = echarts.init(chartDom);
    let chartText = this.language === 'en' ? 'Active & Inactive Alerts by Region' : 'التنبيهات النشطة وغير النشطة حسب المنطقة'
    var option;
    option = {
      title: {
        text: chartText
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        x: 'right'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: {
        type: 'category',
        // data: chartData.regions
        data: this.language == 'en' ? chartData.regionEN : chartData.regionAr
      },
      series: [
        {
          name: chartData.active,
          type: 'bar',
          data: chartData.activeAlerts
        },
        {
          name: chartData.expired,
          type: 'bar',
          data: chartData.expiredAlerts
        }
      ]
    };

    option && this.activeAndNonActiveAlertChart.setOption(option);

  }

  createRegionsAlertsChart(ewsAnalyticsRegionData: EwsAnalyticsReponseModel[], parameter: string) {
    if (this.allRegionAlertsChart) {
      this.allRegionAlertsChart.clear()
      this.allRegionAlertsChart.dispose();
    }
    const chartData = { regionEN: [], regionAr: [], yellowAlerts: [], orangeAlerts: [], redAlerts: [], orange: '', yellow: '', red: '' };

    chartData.regionEN = ewsAnalyticsRegionData?.map((analytics) => analytics.regionEn);
    chartData.regionAr = ewsAnalyticsRegionData?.map((analytics) => analytics.regionAr);
    // here containerId and Data Key is same
    chartData.yellowAlerts = this.getAlertsByColor(ewsAnalyticsRegionData, 'yellow', parameter);
    chartData.orangeAlerts = this.getAlertsByColor(ewsAnalyticsRegionData, 'orange', parameter);
    chartData.redAlerts = this.getAlertsByColor(ewsAnalyticsRegionData, 'red', parameter);
    chartData.orange = this.language === 'en' ? 'Orange' : 'برتقالي';
    chartData.yellow = this.language === 'en' ? 'Yellow' : 'أصفر';
    chartData.red = this.language === 'en' ? 'Red' : 'أحمر';

    var chartDom = document.getElementById('active_archived_chart');
    this.allRegionAlertsChart = echarts.init(chartDom);
    var option;

   let chartTitle = this.getChartTitle();

    option = {
      title: {
        text: chartTitle
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          let result = params[0].name + '<br>';
          params.forEach(function (item) {
            // Adding a circle with the color of the item series
            result += '<span style="display:inline-block;margin:0 4px;border-radius:10px;width:10px;height:10px;background-color:' + item.color + ';"></span>';
            // Assuming 'item' object has a 'seriesName' and 'value' property
            result += item.seriesName + ': ' + item.value + '<br>';
          });
          return result;
        }
      },
      legend: {
        x: 'right'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        // data: chartData.regions
        data: this.language == 'en' ? chartData.regionEN : chartData.regionAr
      },
      series: [
        {
          name: chartData.orange,
          type: 'bar',
          stack: 'total',
          label: {
            show: false
          },
          emphasis: {
            focus: 'series',
          },
          data: chartData.orangeAlerts,
          color: 'orange',
        },
        {
          name: chartData.red,
          type: 'bar',
          stack: 'total',
          label: {
            show: false
          },
          emphasis: {
            focus: 'series',
          },
          data: chartData.redAlerts,
          color: 'red',
        },
        {
          name: chartData.yellow,
          type: 'bar',
          stack: 'total',
          label: {
            show: false
          },
          emphasis: {
            focus: 'series',
          },
          data: chartData.yellowAlerts,
          color: 'yellow',
        },
      ]

    };

    option && this.allRegionAlertsChart.setOption(option);
  }

  getChartTitle(){
    if(!this.archived){
      if(this.language === 'en'){
        return 'Active Alert Type by Region';
      }
      else{
        return 'نوع التنبيه النشط حسب المنطقة';
      }
    }
    else{
      if(this.language === 'en'){
        return 'Archived Alert Type by Region';
      }
      else{
        return ' نوع التنبيه المؤرشف حسب المنطقة'
      }
    }

  }

  getAlertsByColor(data: EwsAnalyticsReponseModel[], color: string, key: string) {
    return data.reduce((result, analytics) => {
      const alert = analytics[key].alert_type_count.find((a) => a.color === color);
      result.push(alert ? alert.count : 0);
      return result;
    }, []);
  }

  clear(table: Table) {
    table.clear();
  }

  archiveEwsNotification(id: string) {
    this.spinner.show();
    const archiveModel: { id: string, status: boolean } = {
      id: id,
      status: true
    }
    this.alertService.archiveEwsNotifcation(archiveModel)
      .then((res: { success: boolean }) => {
        if (res.success) {
          this.spinner.hide();
          this.getEwsNotifications();
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
  }

  alertSearch() {
    this.debounceSubject.next()
  }

  getEwsNotifications(sortfilter?: string) {
    this.searchEwsNotifcationFilterModel = new SearchEwsNotifcationFilterModel();

    if(sortfilter){
      this.sortarray = sortfilter
    }

    if(this.searchByAlertID){
      this.searchEwsNotifcationFilterModel.searchId = this.searchByAlertID
    }
    if (this.selectedRegions.value && this.selectedRegions.value?.length > 0) {
      this.searchEwsNotifcationFilterModel.region = this.selectedRegions.value;
    }
    if (this.selectedAlertActions.value && this.selectedAlertActions.value?.length > 0) {
      this.searchEwsNotifcationFilterModel.alertAction = this.selectedAlertActions.value;
    }
    if (this.selectedStatus.value && this.selectedStatus.value?.length > 0) {
      this.searchEwsNotifcationFilterModel.status = this.selectedStatus.value;
    }
    if (this.selectedAlertTypes.value && this.selectedAlertTypes.value?.length > 0) {
      this.searchEwsNotifcationFilterModel.alertType = this.selectedAlertTypes.value;
    }

    if (this.sortarray === 'sortByAlertStatus') {
      if(sortfilter === 'sortByAlertStatus'){
        this.sortByAlertStatus = !this.sortByAlertStatus
      }
      this.searchEwsNotifcationFilterModel.sortByAlertStatus = this.sortByAlertStatus ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByRegion') {
      if(sortfilter === 'sortByRegion'){
        this.sortByRegion = !this.sortByRegion
      }
      this.searchEwsNotifcationFilterModel.sortByRegion = this.sortByRegion ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByStartDate') {
      if(sortfilter === 'sortByStartDate'){
        this.sortByStartDate = !this.sortByStartDate
      }
      this.searchEwsNotifcationFilterModel.sortByStartDate = this.sortByStartDate ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByEndDate') {
      if(sortfilter === 'sortByEndDate'){
        this.sortByEndDate = !this.sortByEndDate
      }
      this.searchEwsNotifcationFilterModel.sortByEndDate = this.sortByEndDate ? 'asc' : 'desc'
    } else if (this.sortarray === 'sortByCreated') {
      if(sortfilter === 'sortByCreated'){
        this.sortByCreated = !this.sortByCreated
      }
      this.searchEwsNotifcationFilterModel.sortByCreated = this.sortByCreated ? 'asc' : 'desc'
    }

    if(this.fromDate && this.toDate){
      this.searchEwsNotifcationFilterModel.fromDate = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.searchEwsNotifcationFilterModel.toDate = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
    }

    this.searchEwsNotifcationFilterModel.page = this.page
    this.searchEwsNotifcationFilterModel.page_size = this.archived ? this.archivedPageSize : this.pageSize

    this.spinner.show()
    this.alertService.getSearchEwsNotifications(this.searchEwsNotifcationFilterModel)
      .then((res:NEWListEwsNotificationModel) => {
          this.notificationList = res;
      })
      .catch((error: HttpErrorResponse) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  getRegions() {
    this.spinner.show();
    this.alertService.getRegions()
      .then((res: { success: boolean, data: RegionModel[] }) => {
        if (res?.success) {
          this.regions = res.data;
          this.filterRegions = this.regions
          this.allRegIds = []
          setTimeout(() => {
            this.allRegIds = this.regions.map(group => group.id);
          }, 500);
          this.changeActiveAlerts(this.selectedAlertRegions);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      }).finally(() => {
        this.spinner.hide();
      })
  }

  getAlertActions() {
    this.spinner.show();
    this.alertService.getAlertActions()
      .then((res: { success: boolean, data: AlertActionModel[] }) => {
        if (res?.success) {
          this.alertActions = res.data;
        }
      })
      .catch((error) => {
        this.spinner.hide();
        if (error.status === 401) {
          this.toast.error('Session Expired');
          this.router.navigateByUrl('auth/login');
        }
        console.log("error", error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  getAlertType() {
    this.spinner.show();
    this.alertService.getAlertTypes()
      .then((res: { success: boolean, data: AlertTypeModel[] }) => {
        if (res?.success) {
          this.alertTypes = res.data;
        }
      })
      .catch((error) => {
        this.spinner.hide();
        if (error.status === 401) {
          this.toast.error('Session Expired');
          this.router.navigateByUrl('auth/login');
        }
        console.log("error", error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  editAlert(list: EWSNotificationModel | any) {
    localStorage.setItem("tempFile", '')
    const navigationExtras: NavigationExtras = {
      queryParams: {
        list: JSON.stringify(list),
        isUpdate: true,
        isDraft: false,
        isAlreadyPublished: true
      }
    };
    this.router.navigate(['home/alert-editor/add-region'], navigationExtras);
  }

  onAlertRowClick(list: ListEwsNotificationDataModel, isView: boolean) {
    this.isView = isView;
    if(!isView){
      this.isExpanded2 = [];
      this.HistoryTable = true;
    }

    this.alertId = list?.id;
    this.getAlertHistory(list?.id);
    // this.getEwsNotificationEmails(id);

    if (list?.file?.length > 0) {
      const lastSlashIndex = list.file.lastIndexOf('/');
      let fileName = list.file.substring(lastSlashIndex + 1);

      fileName = fileName.replace(/^\d*\/?/, '');
      fileName = fileName.replaceAll('%20', ' ');
      fileName = fileName.replaceAll('%28', '(').replaceAll('%29', ')');

      this.fileName = fileName;
    }
  }

  closeDetails(){
    this.isView = false;
    this.fileName = '';
  }

  getEwsNotificationEmails(id: number) {
    this.spinner.show();
    this.alertService.getEwsNotificationEmailsById(id,this.emailPageNumber, this.emailsPageSize)
      .then((res:listNotificationEmailsResponseModel) => {
        this.emailList = res;
      })
      .catch((error: HttpErrorResponse) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  getAlertHistory(id: number) {
    this.spinner.show();
    this.alertService.getDetailEwsNotifications(id)
      .then((res: { data: Data, history: History[], success: boolean }) => {
        this.detailListData = res?.data
        this.detailList = res?.history
      })
      .catch((error) => {
        this.spinner.hide();
       this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  toggleExpand(index: number) {
    this.isExpanded[index] = !this.isExpanded[index];
  }
  toggleExpand12(index: number) {
    this.isExpanded12[index] = !this.isExpanded12[index];
  }

  toggleExpand2(index: number) {
    this.isExpanded2[index] = !this.isExpanded2[index];
  }
  toggleExpand21(index: number) {
    this.isExpanded21[index] = !this.isExpanded21[index];
  }

  toggleExpand3(index: number) {
    this.isExpanded3[index] = !this.isExpanded3[index];
  }

  sortNotificationList() {
    if (!this.activeNotificationSort || this.activeNotificationSort == 'expired') {
      this.activeNotificationSort = 'active';
    } else {
      this.activeNotificationSort = 'expired';
    }

    this.notificationList.data = this.notificationList.data.sort((a, b) => {
      const statusOfA = this.getDateDifferenceStatus(a.toDate);
      const statusOfB = this.getDateDifferenceStatus(b.toDate);

      if (this.activeNotificationSort === 'active') {
        if (statusOfA === 'active' && statusOfB !== 'active') {
          return -1;
        }
        if (statusOfA !== 'active' && statusOfB === 'active') {
          return 1;
        }
      } else if (this.activeNotificationSort === 'expired') {
        if (statusOfA === 'expired' && statusOfB !== 'expired') {
          return -1;
        }
        if (statusOfA !== 'expired' && statusOfB === 'expired') {
          return 1;
        }
      }
      return 0;
    });
  }

  getDateDifferenceStatus(toDateString: string): string {
    const toParts = toDateString.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}) (AM|PM)/);
    const toDate = new Date(
      parseInt(toParts[3]),
      parseInt(toParts[2]) - 1,
      parseInt(toParts[1]),
      toParts[7] === 'AM' ? parseInt(toParts[4]) : (parseInt(toParts[4]) % 12) + (toParts[7] === 'PM' ? 12 : 0),
      parseInt(toParts[5]),
      parseInt(toParts[6])
    );
    const currentTime = new Date();
    const diffMilliseconds = toDate.getTime() - currentTime.getTime();
    const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));

    if (diffMinutes <= 0) {
      return 'expired';
    }
    else {
      return 'active'
    }
  }

  debounce(func: Function, delay: number) {
    let timeoutId: NodeJS.Timeout;

    return function (...args: any[]) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  @ViewChild('audioElement') audioElement: ElementRef;

  stopAudio() {
    let audios: HTMLAudioElement[] = [...document.querySelectorAll('.audio')] as HTMLAudioElement[];

      if(audios && audios?.length > 0){
        for(let audio of audios){
          audio.pause();
        }
      }
  }

  onPageChange(pageNumber:number){
    this.page = pageNumber;
    this.getEwsNotifications();
  }

  onEmailPageChange(pageNumber:number){
    this.emailPageNumber = pageNumber;
    this.getEwsNotificationEmails(this.alertId);
  }


  showarchive(id: string) {
    this.isArchive = true;
    this.archiveId = id;
  }

  cancelarchive() {
    this.isArchive = false;
  }

  changeActiveAlerts(region: string) {
    this.spinner.show();
    this.selectedAlertRegions = region;
    this.ewsAnalyticsResponse.forEach((analytics) => {
      if (analytics.regionEn === this.selectedAlertRegions) {
        this.AlertCount = analytics.active.alert_type_count
      }
    });
    const searchEwsNotifcationFilterModel: SearchEwsNotifcationFilterModel = new SearchEwsNotifcationFilterModel();
    searchEwsNotifcationFilterModel.region = [this.regions.find((region) => region.name_en === this.selectedAlertRegions).id];
    searchEwsNotifcationFilterModel.page_size = 100;
    searchEwsNotifcationFilterModel.page = 1;
    searchEwsNotifcationFilterModel.status = ['active'];
    this.alertService.getSearchEwsNotifications(searchEwsNotifcationFilterModel)
    .then((res:NEWListEwsNotificationModel) => {
      const AlertStatusesByAlertTypes = {
        Yellow: [],
        Orange: [],
        Red: []
      };

      const uniqueStatuses = {
        Yellow: new Set(),
        Orange: new Set(),
        Red: new Set()
      };

      res.data.forEach(alert => {
        const alertTypeEn = alert.alertTypeEn;
        const alertStatusEn = alert.alertStatusEn;
        const alertStatusAr = alert.alertStatusAr;

        if (!uniqueStatuses[alertTypeEn].has(alertStatusEn)) {
          AlertStatusesByAlertTypes[alertTypeEn].push({
            alertStatusEn,
            alertStatusAr
          });

          uniqueStatuses[alertTypeEn].add(alertStatusEn);
        }
      });

      this.AlertStatusesByAlertTypes = AlertStatusesByAlertTypes;
    })
    .catch((error: HttpErrorResponse) => {
      this.globalService.handleError(error);
    })
    .finally(() => {
      this.spinner.hide();
    })
  }

  updateFilteredItems(filtered: any[], section: string) {
    if (section === 'Reg') {
      this.filterRegions = filtered;
    } else if (section === 'ewsAnalyticsResponse') {
      this.filterewsAnalyticsResponse = filtered;
    }
  }

  areGovernoratesEqual(currentGovs: Governorate[], previousGovs: Governorate[]): boolean {
    if (currentGovs?.length !== previousGovs?.length) return false;
    for (let i = 0; i < currentGovs?.length; i++) {
      if (currentGovs[i]?.nameEn !== previousGovs[i]?.nameEn || currentGovs[i]?.nameAr !== previousGovs[i]?.nameAr) {
        return false;
      }
    }
    return true;
  };

  alertActionsWth(history: History[], index: number): string[] {
    const alertActions: string[] = [];
    const current = history[index];
    const previous = history[index + 1];
    let text: string;

    if(current?.alertActions[0]?.descriptionEn === 'New Alert'){
      text = this.language === 'en' ? current?.alertActions[0]?.descriptionEn : current?.alertActions[0]?.descriptionAr;
      alertActions.push(text);
    } else {
      if(current?.alertTypeEn !== previous?.alertTypeEn){
        for (let i = 0; i < current.alertActions.length; i++) {
          if(current.alertActions[i]?.descriptionEn === 'Raise Alert' || current.alertActions[i]?.descriptionEn === 'Lower Alert'){
            text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + current?.alertTypeEn + ')' : current.alertActions[i]?.descriptionAr + ' (' + current?.alertTypeAr + ')';
            alertActions.push(text);
          }
        }
      }
      if(current?.alertStatusEn !== previous?.alertStatusEn){
        for (let i = 0; i < current.alertActions.length; i++) {
          if(current.alertActions[i]?.descriptionEn === 'Update Alert Status'){
            text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + current?.alertStatusEn + ')' : current.alertActions[i]?.descriptionAr + ' (' + current?.alertStatusAr + ')';
            alertActions.push(text);
          }
        }
      }
      if(!this.areGovernoratesEqual(current?.governorates, previous?.governorates)){
        for (let i = 0; i < current.alertActions.length; i++) {
          if(current.alertActions[i]?.descriptionEn === 'Update Alert Location'){
            const govEN = current?.governorates.map(gov => gov.nameEn).join(', ');
            const govAR = current?.governorates.map(gov => gov.nameAr).join(', ');
            text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + govEN + ')' : current.alertActions[i]?.descriptionAr + ' (' + govAR + ')';
            alertActions.push(text);
          }
        }
      }
      if(current?.toDate !== previous?.toDate){
        for (let i = 0; i < current.alertActions.length; i++) {
          if(current.alertActions[i]?.descriptionEn === 'Update Alert Period'){
            text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + this.datePipe.transform(current?.toDate, "dd/MM/yyyy hh:mm a", "", "en") + ')' : current.alertActions[i]?.descriptionAr + ' (' + this.datePipe.transform(current?.toDate, "dd/MM/yyyy hh:mm a", "", "ar") + ')';
            alertActions.push(text);
          }
        }
      }
      // if(current?.alertTypeEn === previous?.alertTypeEn){
      //   for (let i = 0; i < current.alertActions.length; i++) {
      //     if(current.alertActions[i]?.descriptionEn === 'Raise Alert' || current.alertActions[i]?.descriptionEn === 'Lower Alert'){
      //       text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + current?.alertTypeEn + ')' : current.alertActions[i]?.descriptionAr + ' (' + current?.alertTypeAr + ')';
      //       alertActions.push(text);
      //     }
      //   }
      // }
      // if(current?.alertStatusEn === previous?.alertStatusEn){
      //   for (let i = 0; i < current.alertActions.length; i++) {
      //     if(current.alertActions[i]?.descriptionEn === 'Update Alert Status'){
      //       text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + current?.alertStatusEn + ')' : current.alertActions[i]?.descriptionAr + ' (' + current?.alertStatusAr + ')';
      //       alertActions.push(text);
      //     }
      //   }
      // }
      // if(this.areGovernoratesEqual(current?.governorates, previous?.governorates)){
      //   for (let i = 0; i < current.alertActions.length; i++) {
      //     if(current.alertActions[i]?.descriptionEn === 'Update Alert Location'){
      //       const govEN = current?.governorates.map(gov => gov.nameEn).join(', ');
      //       const govAR = current?.governorates.map(gov => gov.nameAr).join(', ');
      //       text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + govEN + ')' : current.alertActions[i]?.descriptionAr + ' (' + govAR + ')';
      //       alertActions.push(text);
      //     }
      //   }
      // }
      // if(current?.toDate === previous?.toDate){
      //   for (let i = 0; i < current.alertActions.length; i++) {
      //     if(current.alertActions[i]?.descriptionEn === 'Update Alert Period'){
      //       text = this.language === 'en' ? current.alertActions[i]?.descriptionEn + ' (' + this.datePipe.transform(current?.toDate, "dd/MM/yyyy hh:mm a", "", "en") + ')' : current.alertActions[i]?.descriptionAr + ' (' + this.datePipe.transform(current?.toDate, "dd/MM/yyyy hh:mm a", "", "ar") + ')';
      //       alertActions.push(text);
      //     }
      //   }
      // }
    }

    if(alertActions.length === 0){
      this.noChanges[index] = true
    }
    return alertActions;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
      this.page = 1;
      this.getEwsNotifications();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  clearFilter() {
    if(this.fromDate && this.toDate){
      this.fromDate = null;
      this.toDate = null;
      this.page = 1;
      this.getEwsNotifications();
    }
  }

  formatDate(date: NgbDate): string {
    return `${date.year}-${this.padNumber(date.month)}-${this.padNumber(date.day)}`;
  }

  padNumber(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  get dateRangeText(): string {
    if (this.fromDate && this.toDate) {
      return `${this.formatDate(this.fromDate)} to ${this.formatDate(this.toDate)}`;
    } else if (this.fromDate) {
      return `${this.formatDate(this.fromDate)}`;
    }
    return '';
  }

  generatePDF(alert_id: number) {
    this.spinner.show();
    this.alertService.generateHistoryReport(alert_id)
      .then((res: {data: string, success: boolean, message: string}) => {
        if(res.success){
          const link = document.createElement('a');
          link.href = res.data;
          link.target = '_blank';
          link.click();
        } else {
          this.toast.error(res.message);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
