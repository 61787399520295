import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PermissionsGuard } from "../guards/permissions-guard/permissions.guard";
import { PERMISSIONS } from "../constants/constants";

const routes: Routes = [
  {
    path: "alert-history",
    loadChildren: () => import('./alert-history/alert-history.module').then((m) => m.AlertHistoryModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.EARLY_WARNING],
      }
    }
  },
  {
    path: "alert-history?archived=true",
    loadChildren: () => import('./alert-history/alert-history.module').then((m) => m.AlertHistoryModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.EARLY_WARNING],
      }
    }
  },
  {
    path: "addusers",
    loadChildren: () => import('./add-users/add-users.module').then((m) => m.AddUsersModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.USERS],
      }
    }
  },
  {
    path: "addclient",
    loadChildren: () => import('./add-client/add-client.module').then((m) => m.AddClientModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.CLIENTS],
      }
    }
  },
  {
    path: "alert-editor",
    loadChildren: () => import('./alert-editor/alert-editor.module').then((m) => m.AlertEditorModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.CREATE_EARLY_WARNING],
      }
    }
  },
  {
    path: "profile",
    loadChildren: () => import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: "user-group",
    loadChildren: () => import('./user-group/user-group.module').then((m) => m.UserGroupModule),
  },
  {
    path: 'early-warning',
    loadChildren: () => import("./test-early-warnings/test-early-warnings.module").then((m) => m.TestEarlyWarningsModule),
  },
  {
    path: "manned-forecast",
    loadChildren: () => import('./manned-forecast/manned-forecast.module').then((m) => m.MannedForecastModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.REPORT],
      }
    }
  },
  {
    path: "automatic-weather-report",
    loadChildren: () => import('./automatic-weather-report/automatic-weather-report.module').then((m) => m.AutomaticWeatherReportModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.REPORT],
        redirectTo: 'home/profile'
      }
    }
  },
  {
    path: "packages",
    loadChildren: () => import('./packages/packages.module').then((m) => m.PackagesModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [PERMISSIONS.PACKAGE],
        redirectTo: 'home/profile'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule { }
