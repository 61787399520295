<!-- <div class="page-titlealert">
  <i class="fas fa-caret-right"></i>
  <p class="active">{{draftPage?.AlertEditorPreview}}</p>
</div> -->
<!-- <div class="locationBG justify-content-between">
  <div class="addLocHeading">
    <i class="fas fa-bell"></i>
    <h3>{{draftPage?.AlertDrafts}}</h3>
  </div>
</div> -->
<p-toolbar class="row dome-d mb-30" style="margin: inherit;">
  <div class="p-toolbar-group-left col justify-content-between">
    <div class="d-flex gap-2 align-items-center">
      <img src=".././../../../assets/Icon-awesome-bell.png" style="scale: 0.7;">
      <div class="alert-history">{{draftPage?.AlertDrafts}}</div>
    </div>
  </div>
</p-toolbar>

<div class="AddUserTable">
  <div class="d-flex flex-wrap justify-content-between align-items-center mb-2" style="padding: 1rem 1.188rem 0.5rem;">
    <div class="addLocHeading col-xl-3 col-lg-12 col-12">
      <h3>{{draftPage?.SaveDrafts}}</h3>
    </div>
    <div class="d-flex flex-wrap justify-content-end gap-4 col-xl-9 col-lg-12 col-12">
      <div class="col-1 d-flex position-relative" style="top: 2px;max-width: min-content;" ngbDropdown>
        <div class="date-picker-container h-100">
          <button (click)="clearFilter()"
            id="btn-cal"
            class="btn btn-light filters-like"
            style.background-color="{{fromDate || toDate ? '#149fdf25' : ''}}"
            type="button"
            ngbDropdownToggle>
            <svg xmlns="http://www.w3.org/2000/svg" width="34.476" height="34.476" viewBox="0 0 34.476 34.476">
              <g id="Group_117726" data-name="Group 117726" transform="translate(-1702.5 -1395.449)">
                <path id="Path_64875" data-name="Path 64875" d="M1706.809,1400.836v-4.309a1.077,1.077,0,1,1,2.155,0v4.309a1.077,1.077,0,1,1-2.155,0Zm18.316,1.078a1.077,1.077,0,0,0,1.078-1.078v-4.309a1.078,1.078,0,1,0-2.155,0v4.309a1.077,1.077,0,0,0,1.077,1.078Zm11.851,19.393a8.619,8.619,0,1,1-8.619-8.619,8.62,8.62,0,0,1,8.619,8.619Zm-2.155,0a6.464,6.464,0,1,0-6.464,6.464,6.471,6.471,0,0,0,6.464-6.464Zm-23.7-12.928h-4.309v4.309h4.309Zm-4.309,10.774h4.309v-4.31h-4.309Zm6.464-6.464h4.309v-4.309h-4.309Zm0,6.464h4.309v-4.31h-4.309Zm-8.619,1.94v-14.869h23.7v4.309h2.155v-8.4a2.351,2.351,0,0,0-2.334-2.37h-.9v1.077a2.155,2.155,0,1,1-4.309,0v-1.077h-12.928v1.077a2.155,2.155,0,1,1-4.31,0v-1.077h-.9a2.352,2.352,0,0,0-2.335,2.37v18.964a2.354,2.354,0,0,0,2.335,2.369h12.749v-2.155h-12.749a.2.2,0,0,1-.18-.214Zm19.393-8.4v-4.309h-4.309v4.309Zm7.542,8.619h-3.232v-3.232a1.077,1.077,0,1,0-2.154,0v4.309a1.077,1.077,0,0,0,1.077,1.078h4.31a1.077,1.077,0,0,0,0-2.155Zm0,0" fill="#8795aa"/>
              </g>
            </svg>
          </button>
          <!-- <span *ngIf="fromDate || toDate" [ngClass]="language === 'en' ? 'clear-r' : 'clear-l'" class="clear-icon" (click)="clearFilter()">&#x2715;</span> -->
        </div>

        <div class="p-0" ngbDropdownMenu>
          <ngb-datepicker
            #dp
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="1"
            [dayTemplate]="t"
            outsideDays="hidden">
          </ngb-datepicker>
        </div>
      </div>

      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="d-flex gap-2 flex-wrap" style="padding: 0 1.188rem 1rem;">
    <div class="ews-color-legend">
      {{draftPage?.AlertType}}: &nbsp;&nbsp;&nbsp; <div class="yellow circle"></div> &nbsp; {{draftPage?.Yellow}} &nbsp;&nbsp; <div class="orange circle"></div> &nbsp; {{draftPage?.Orange}} &nbsp;&nbsp; <div class="red circle"></div> &nbsp; {{draftPage?.Red}}
    </div>
    <!-- <div *ngIf="!archived" class="ews-color-legend">
      {{draftPage?.Status}}: &nbsp;&nbsp;&nbsp; <div class="active_green"></div> &nbsp; {{draftPage?.Active}} &nbsp;&nbsp; <div class="expired_grey"></div> &nbsp; {{draftPage?.Expired}}
    </div> -->
  </div>
  <div class="client-table mb-30">
    <table class="customTable">
      <thead>
        <tr>
          <th>{{draftPage?.StartDateAndTime}}</th>
          <th>{{draftPage?.EndDateAndTime}}</th>
          <th>{{draftPage?.AlertAction}}</th>
          <th>{{draftPage?.AlertType}}</th>
          <th>{{draftPage?.AlertStatus}}</th>
          <th>{{draftPage?.Region}}</th>
          <th>{{draftPage?.Governorate}}</th>
          <th>{{draftPage?.Createdat}}</th>
          <th class="text-center">{{draftPage?.Actions}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="notificationList?.paginationVariables?.totalCount > 0">
          <ng-container *ngFor="let list of notificationList?.data; index as i">
            <tr style="cursor: pointer;">
              <td>{{list?.fromDate | arabicDate}}</td>
              <td>{{list?.toDate | arabicDate}}</td>
              <td>
                <div *ngFor="let action of  list?.alertActions">
                  {{ language === 'en' ? action?.descriptionEn : action?.descriptionAr}}
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center gap-2">
                  <div [ngClass]="{'yellow': list?.alertTypeEn.toLowerCase() === 'yellow', 'red': list?.alertTypeEn.toLowerCase() === 'red',
                'orange': list?.alertTypeEn.toLowerCase() === 'orange'}" class="alert-type"></div>
                  <ng-container *ngIf="language === 'en' else typeArabic">
                    {{list?.alertTypeEn}}
                  </ng-container>
                  <ng-template #typeArabic>
                    {{list?.alertTypeAr}}
                  </ng-template>
                </div>
              </td>
              <td>
                <ng-container *ngIf="language === 'en' else statusArabic">
                  {{list?.alertStatusEn}}
                </ng-container>
                <ng-template #statusArabic>
                  {{list?.alertStatusAr}}
                </ng-template>
              </td>
              <td>
                <ng-container *ngIf="language === 'en' else regionArabic">
                  {{list?.regionEn}}
                </ng-container>
                <ng-template #regionArabic>
                  {{list?.regionAR}}
                </ng-template>
              </td>
              <!-- <td>
                <ng-container *ngFor="let governorate of list?.governorates; index as index">
                  <ng-container *ngIf="language === 'en' else govArabic">
                    {{governorate?.nameEn}}
                  </ng-container>
                  <ng-template #govArabic>
                    {{governorate?.nameAr}}
                  </ng-template>
                  <ng-container *ngIf="index !== list?.governorates.length - 1"> -
                    <pre *ngIf="index % 2 === 1"></pre>
                  </ng-container>
                </ng-container>
              </td> -->
              <td [ngClass]="isExpanded[i] ? 'back-color' : ''" class="governorate-body">
                <div class="d-flex" [ngClass]="isExpanded[i] ? 'expanded-governorates' : language === 'ar' ? 'collapsed-governorates' : 'collapsed-governorates-id'">
                  <div *ngIf="list?.governorates.length > 1" class="col-2 d-flex align-items-center"
                    [ngClass]="isExpanded[i] ? 'expanded-governorates' : language === 'ar' ? 'collapsed-governorates' : 'collapsed-governorates-id'">
                    <i [ngClass]="isExpanded[i] ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'"
                    class="fas collapse-btn" (click)="$event.stopPropagation();toggleExpand(i)"></i>
                  </div>
                  <div class="col-10 d-flex flex-column table-mids">
                    <span class="my-0_4">{{ language === 'en' ? list?.governorates?.[0]?.nameEn : list?.governorates?.[0]?.nameEn }}</span>
                    <ul class="m-0 p-0">
                      <ng-container *ngFor="let action of list?.governorates.slice(1); index as index;">
                        <li class="fw04">{{ language === 'en' ? action?.nameEn : action?.nameEn }}</li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </td>
              <td>{{(list?.created_at | arabicDate).split(" ")[0]}}</td>
              <td>
                <div class="d-flex align-items-center gap-4 justify-content-center">
                  <!-- <div (click)="showpublish(list.id)" class="d-flex align-items-center gap-4 no-wrap">
                    <button [ngbTooltip]="draftPage?.Publish" tooltipClass="custom-tooltip">
                      <img src="../../../../assets/alerteditor/publish-icon.png">
                    </button>
                  </div> -->
                  <div (click)="editAlert(list)" class="d-flex align-items-center gap-4 no-wrap">
                    <button [ngbTooltip]="draftPage?.Edit" tooltipClass="custom-tooltip">
                      <img src="../../../../assets/alerteditor/edit-icon-small.png">
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>

    <div class="d-flex justify-content-center no-data" *ngIf="isLoadingListAPI; else noRecordsTemplate">
      <i class="fa fa-spinner spinner--icon"></i>
    </div>
    <ng-template #noRecordsTemplate>
      <ng-container *ngIf="notificationList?.paginationVariables?.totalCount == 0 || !notificationList?.data">
        <tr>
          <td colspan="8">
            <div class="d-flex justify-content-center no-data">
              {{draftPage?.CurrentlyNoDraftsToShow}}
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </div>
  <div *ngIf="notificationList?.paginationVariables?.totalCount > 0"
    class="col-12 d-flex justify-content-start pagination dir-left mb-30">
    <div style="visibility: hidden;" class="col">h</div>
    <div class="d-flex justify-content-center col">
      <ngb-pagination *ngIf="notificationList?.paginationVariables?.pageCount > 1"
        (pageChange)="onPageChange($event)" [maxSize]="3" [rotate]="true" [pageSize]="pageSize"
        [(page)]="page" [collectionSize]="notificationList?.paginationVariables?.totalCount"></ngb-pagination>
    </div>
    <div class="d-flex justify-content-end col addLocHeading">
      <h3>{{draftPage?.TotalNumberOfAlerts}}: {{notificationList?.paginationVariables?.totalCount}}</h3>
    </div>
  </div>
  <!-- <div *ngIf="notificationList?.paginationVariables?.pageCount > 1" class="col-12 d-flex justify-content-center pagination dir-left mb-30">
    <ngb-pagination [maxSize]="3" [rotate]="true" [pageSize]="pageSize" [(page)]="page" (pageChange)="onPageChange($event)"
      [collectionSize]="notificationList?.paginationVariables?.totalCount"></ngb-pagination>
  </div> -->
</div>
<div *ngIf="isPublish" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancelpublish()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{draftPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{usersPage?.DoYouReallyWant}}
    </p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="publishEwsNotification(publishId);cancelpublish()">
        <div class="publish-img"></div>
        {{draftPage?.Yes}}
      </button>
      <button class="cancel-btn" (click)="cancelpublish()">
        <i class="fas fa-times"></i>
        {{draftPage?.No}}
      </button>
    </div>
  </div>
</div>
