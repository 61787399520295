// API Re-Fetch Timer
export const APIReFetchTimer = 1800000
//routes
export const MANNEDALERTS_PAGE_ROUTE = '/home/manned-alerts';
export const ADD_CLIENTS = '/home/addclient';
export const ADD_USERS = '/home/addusers';
export const ALERTEDITOR_PAGE_ROUTE = '/home/addregion';
export const ALERTHISTORY_PAGE_ROUTE = '/home/alert-history';
export const ALERTDRAFTS_PAGE_ROUTE = '/home/alert-editor/draft';
export const PROFILE = '/home/profile';
export const PACKAGES = '/home/packages';
export const MANNED_FORECAST_PAGE_ROUTE = '/home/manned-forecast';
export const AUTOMATIC_WEATHER_REPORT_PAGE_ROUTE = '/home/automatic-weather-report';
export const ARCHIVED_PAGE_ROUTE = '/home/alert-history?archived=true';
export const USER_GROUP_PAGE_ROUTE = '/home/user-group';
export const EWS_LIST_WARNING_ROUTE = '/home/early-warning';

export enum PERMISSIONS {
    EARLY_WARNING = 'search_ews_notification',
    CREATE_EARLY_WARNING = 'create_ews_notification',
    USERS = 'list_ncm_user',
    PACKAGE = 'list_package',
    CLIENTS = 'organization_list_users',
    REPORT = 'list_report',
    PROFILE = 'get_profile'
}

export const TITLE_TRANSLATIONS = {
    '/home/manned-alerts': {
      en: 'Manned Alerts',
      ar: 'التنبيهات المأهولة'
    },
    '/home/addclient': {
      en: 'Clients',
      ar: 'العملاء'
    },
    '/home/addusers': {
      en: 'Users',
      ar: 'المستخدمين'
    },
    '/home/packages': {
      en: 'Packages',
      ar: 'الباقات'
    },
    '/home/alert-editor/add-region': {
      en: 'Alert Editor',
      ar: 'محرر التنبيه'
    },
    '/home/alert-history': {
      en: 'Dashboard',
      ar: 'لوحة التحكم'
    },
    '/home/alert-editor/draft': {
      en: 'Alert Drafts',
      ar: 'مسودة الإنذارات'
    },
    '/home/manned-forecast': {
      en: 'Manned Forecast',
      ar: 'التوقعات المأهولة'
    },
    '/home/automatic-weather-report': {
      en: 'Weather Report',
      ar: 'تقرير الطقس التلقائي'
    },
    '/home/automatic-weather-report/automatic-weather-report-details': {
      en: 'Create Report',
      ar: 'إنشاء تقرير'
    },
    '/home/automatic-weather-report/automatic-weather-report-preview': {
      en: 'Preview Report',
      ar: 'معاينة التقرير' // CORRECT TRANSLATION NEEDED
    },
    '/home/alert-history%3Farchived%3Dtrue': {
      en: 'Archived Alerts',
      ar: 'التحذيرات المؤرشفة'
    },
    '/home/user-group': {
      en: 'User Groups',
      ar: 'مجموعات الاعضاء'
    },
    '/home/profile': {
      en: 'Profile',
      ar: 'الملف'
    },
    '/auth/login': {
      en: 'Login',
      ar: 'تسجيل الدخول'
    },
    '/auth/signup': {
      en: 'Signup',
      ar: 'إنشاء حساب'
    },
    '/auth/forgot-password': {
      en: 'Forgot Password',
      ar: 'نسيت كلمة المرور'
    },
    '/auth/reset-password': {
      en: 'Reset Password',
      ar: 'إعادة تعيين كلمة المرور'
    },
    default: {
      en: 'Admin Portal',
      ar: 'منصة أجواء' // CORRECT TRANSLATION NEEDED
    }
  };
  