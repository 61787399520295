import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {
  transform(fromDateString: string, toDateString: string): Observable<{ hours: number, minutes: number }> {
    const fromParts = fromDateString.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
    const toParts = toDateString.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);

    const toDate = new Date(
      parseInt(toParts[1]),
      parseInt(toParts[2]) - 1,
      parseInt(toParts[3]),
      parseInt(toParts[4]),
      parseInt(toParts[5]),
      parseInt(toParts[6])
    );

    const currentTime = new Date();
    let diffMilliseconds = toDate.getTime() - currentTime.getTime();
    
    if (diffMilliseconds < 0) {
      diffMilliseconds = 0;
    }

    const totalMinutes = Math.ceil(diffMilliseconds / (1000 * 60));
    const diffHours = Math.floor(totalMinutes / 60);
    const diffMinutes = totalMinutes % 60;

    return timer(0, 60000).pipe(
      map((count) => {
        const remainingMinutes = totalMinutes - count;
        if (remainingMinutes <= 0) {
          return { hours: 0, minutes: 0 };
        }
        const hours = Math.floor(remainingMinutes / 60);
        const minutes = remainingMinutes % 60;
        return { hours, minutes };
      }),
      takeWhile((time) => time.hours > 0 || time.minutes > 0, true)
    );
  }
}
