import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ColorName'
})
export class ColorNamePipe implements PipeTransform {

    transform(value: string, language?:string): string {
        let lang = localStorage.getItem('language');
        if(language){
            lang = language;
        }

        if(lang === 'en'){
            if(value == 'red'){
                return 'Red Warning'
            }
            else if(value == 'orange'){
                return 'Orange Warning'
            }
            else if(value == 'yellow'){
                return 'Yellow Warning'
            }
            else{
                return value;
            }
        }
        else{
            if(value == 'red'){
                return 'الإنذار الأحمر'
            }
            else if(value == 'orange'){
                return 'الإنذار البرتقالي'
            }
            else if(value == 'yellow'){
                return 'الإنذار الأصفر'
            }
            else{
                return value;
            }
        }
    }
}
