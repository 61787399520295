import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorFormatEws'
})
export class ColorFormatEWSPipe implements PipeTransform {
  transform(colorName: string): string {
    colorName.toLowerCase();
    switch (colorName) {
      case 'Yellow':
      case 'yellow':
        return '#FFFF54';
      case 'Orange':
      case 'orange':
        return '#F2A93B';
      case 'Red':
      case 'red':
        return '#D54040';
      // Add more color mappings as needed
      default:
        return colorName; // Return the input color if no match found
    }
  }
}
