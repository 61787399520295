import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorConversionEws'
})
export class ColorConversionEWSPipe implements PipeTransform {

  transform(colorName: string): string {
    colorName.toLowerCase();
    switch (colorName) {
      case 'Yellow':
      case 'yellow':
        return '#FFFF542b';
      case 'Orange':
      case 'orange':
        return '#F2A93B2b';
      case 'Red':
      case 'red':
        return '#D540402b';
      // Add more color mappings as needed
      default:
        return colorName; // Return the input color if no match found
    }
  }
}
