import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Map, FeatureGroup } from "leaflet";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EWSNotificationModel, ListEwsNotificationModel } from 'src/app/models/EWSnotification/EWSnotification.model';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import "leaflet";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
import * as esriVector from "esri-leaflet-vector";
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';
import { isBefore } from 'date-fns';
import { GlobalService } from 'src/app/shared/utilities/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserGroupsService } from 'src/app/services/user-groups/user-groups.service';
import { CreateUserGroupModelAPI, ListUserGroupModelAPI } from 'src/app/models/user-groups/user-groups.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { PreviewComponent } from '../preview/preview.component';
import { environment } from 'src/environments/environment';
import * as mapboxgl from 'mapbox-gl';

declare var google: any;
declare var L: any;


type GroupedGovernateModel = {
  id: number;
  nameEn: string;
  nameAr: string;
  longitude: string;
  latitude: string;
  governate: number;
  data: MunicipalityModel[];
}

interface RegionModel {
  id: number;
  name_en: string;
  name_ar: string;
  longitude: string;
  latitude: string;
}

interface GovernateModel extends RegionModel {
  nameAr: string;
  nameEn: string;
}

interface GovernateAndMuncipalitiesModel extends RegionModel {
  muncipalities?: MunicipalityModel[];
}

interface AlertActionModel {
  id: number;
  nameAr: string;
  nameEn: string;
  severity: string;
}

interface AlertTypeModel {
  id: number;
  color: string;
  nameEn: string;
  nameAr: string
}

interface AlertStatusModel {
  id: number;
  nameEn: string;
  nameAr: string;
  // criteria: string
}

interface AlertHazardModel {
  id: number;
  nameEn: string;
  nameAr: string;
  OrderId: number
}
interface MunicipalityModel {
  id: number;
  latitude: string;
  longitude: string;
  nameAr: string;
  nameEn: string;
  governate: number;
}

interface Muncipality {
  id: number
  nameEn: string
  nameAr: string
  regionId: RegionId
  longitude: string
  latitude: string
  isHidden: boolean
  IsMunicipality: boolean
  MunicipalityID: number
}

interface RegionId {
  id: number
  nameEn: string
  nameAr: string
  longitude: string
  latitude: string
}

interface GetGovernoratesModel {
  id: number
  nameEn: string
  nameAr: string
  regionId: RegionId
  longitude: string
  latitude: string
  isHidden: any
  IsMunicipality: any
  MunicipalityID: any
  ar?: string
  en?: string
}

interface ResponseModel<T> {
  success: boolean;
  data: T;
}

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss'],
})
export class RegionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('preview', { static: false }) preview: PreviewComponent;
  @ViewChild('fileBtn', { static: false }) fileInput: ElementRef;
  private subscription: Subscription = new Subscription();
  language: string;

  ewsNotificationId = new FormControl<number>(null);
  // ewsNotificationFileName = new FormControl<string>(null);
  selectedAlertAction = new FormControl<number[]>([], {
    validators: Validators.required
  });
  selectedRegion = new FormControl(null, {
    validators: Validators.required
  });
  selectedGovernates = new FormControl<number[]>([], {
    validators: Validators.required
  });
  selectedMunicipalities = new FormControl<number[]>([],);
  filterMunicipality = new FormControl<string>('',);
  filterGovernorates = new FormControl<string>('',);
  filterRegions = new FormControl<string>('',);
  filterStatus = new FormControl<string>('',);
  filterUG = new FormControl<string>('',);
  selectedOtherLocations : number[] = [];
  selectedStartDate = new FormControl(null, {
    validators: Validators.required
  })
  selectedEndDate = new FormControl(null, {
    validators: Validators.required
  })
  selectedStartTime = new FormControl(null, {
    validators: Validators.required
  })
  selectedEndTime = new FormControl(null, {
    validators: Validators.required
  })
  message = new FormControl(null, {
    validators: Validators.required
  })
  x_post = new FormControl(null, {
    validators: Validators.required
  })
  selectedAlertStatus = new FormControl(null, {
    validators: Validators.required
  })
  selectedAlertHazard = new FormControl(null, {
    validators: Validators.required
  })
  selectedAlertType = new FormControl(null, {
    validators: Validators.required
  })

  enableTwitterNotification = new FormControl<boolean>(true);
  enableSMSNotification = new FormControl<boolean>(true);
  enableEmailNotification = new FormControl<boolean>(true);
  // enableEmailGroups = new FormControl<boolean>(null);
  enableEmailGroups: boolean = true;
  selectedEmailGroups: number[] = [];

  selectedRegionName: RegionModel;

  initializeMap: boolean = false;
  @ViewChild('mapContainer') mapContainer!: ElementRef;
  @ViewChild('startDateContainer') startDateContainer: ElementRef;
  map: mapboxgl.Map;
  // map!: Map;
  featureGroup!: FeatureGroup<any>;
  currentMarker: L.Marker | null = null;
  currentPolygon: L.Polygon | null = null;

  options: L.MapOptions = {
    layers: [
      new esriVector.VectorTileLayer("https://stgisudp.momra.gov.sa/server/rest/services/Hosted/Public_Basemap/VectorTileServer", {
        apikey: environmentBaseUrl.esriKey,
        pane: 'basemap',
      }),
    ],
    minZoom: 6,
    zoom: 6,
    center: L.latLng(23.8859, 45.0792),
    maxBounds: L.latLngBounds(
      L.latLng(15.6140, 34.4572),
      L.latLng(32.1610, 55.6667)
    )
  };

  drawOptions: any = {
    position: 'topright',
    circle: {},
    rectangle: {},
    polygon: {},
    marker: {},
    polyline: { position: false }
  };
  pointOfInterest: boolean = false;
  isUpsertModal: boolean = false;
  coordinates: any = [];
  name: string;
  address_components: any[];
  latitude: string;
  longitude: string;
  checked: boolean = false;
  regions: RegionModel[];
  governates: GovernateModel[] = [];
  alertActions: AlertActionModel[] = [];
  alertTypes: AlertTypeModel[] = [];
  alertStatus: AlertStatusModel[] = [];
  filteredStatus: AlertStatusModel[] = [];
  fileName: string;
  base64String: string;
  alertHazards: AlertHazardModel[] = [];
  notificationList: ListEwsNotificationModel;
  municipalities: MunicipalityModel[] = [];
  groupedMuncipalities:GroupedGovernateModel[] = [];
  selectedGovernatesAndMuncipalitiesInfo: GovernateAndMuncipalitiesModel[] = [];
  selectedGovernoratesInfo: GovernateModel[] = [];
  regionLayers: any;
  governoratesLayers: any;
  regionPage: any = {};
  isUpdate: boolean = false;
  isDraft: boolean = false;
  isAlreadyPublished: boolean = false;
  simpleMapScreenshoter: any;
  allGovernatesLayers: L.GeoJSON[] = [];
  today: Date;
  OtherMunicipalities: Muncipality[] = [];
  automaticdetailPage: any;

  currentPageForUserGroups: number = 1;
  listUserGroups: CreateUserGroupModelAPI[];
  ListPagination: PaginationVariables;
  alertCheck: number;
  viewPreview: boolean;
  base64Image: string | undefined;
  navigationExtras: NavigationExtras;
  workPreview: boolean = false;
  allMunicipalityIds: number[];
  allHazardIds: number[];
  filteredGroupedMuncipalities: { data: MunicipalityModel[]; id: number; nameEn: string; nameAr: string; longitude: string; latitude: string; governate: number; }[];
  filteredGov: GovernateModel[];
  allGovernatesIds: number[];
  allUGIds: number[];
  filteredReg: RegionModel[];
  filterlistUserGroups: CreateUserGroupModelAPI[];
  allGov: GetGovernoratesModel[];
  XpostIMG: string;
  saved: boolean = false;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userGroupService:UserGroupsService,
    private globalService: GlobalService,
    private modalservice: NgbModal
  ) {
    this.getDataFromParams();
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.fixMap();
      this.translate.get("CreateEWS").subscribe((res) => {
        this.regionPage = res;
      });
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticdetailPage = res;
      });
    }));

    this.subscription.add(
      this.selectedGovernates.valueChanges.subscribe((governoratesIds: number[] = []) => {
        if (governoratesIds && governoratesIds?.length > 0) {
          const uniqueGovernatesInfo: GovernateModel[] = [];
          this.unFillAllGovernatesLayerOnMap();
          governoratesIds.forEach((govid) => {
            this.fillGovernatesByRegionIdOnMap(govid);
            uniqueGovernatesInfo.push(this.governates.find((govern) => govern.id === govid));
          });
          this.selectedGovernoratesInfo = [...new Set(uniqueGovernatesInfo)];
        }
      }));

    this.subscription.add(
      this.selectedMunicipalities.valueChanges.subscribe((muncipalitiesIds: number[]) => {
        this.getMuncipalitiesInfo(muncipalitiesIds);
      })
    );
    this.getRegions();
  }

  ngOnInit() {
    this.getAlertActions();
    this.getAlertType();
    this.getCurrentDate();
    this.getUserGroupsForDropdown('', 1)
    // this.getAlertHazards();
    if(this.isUpdate){
      if(!this.isDraft){
        const updatedActions = this.selectedAlertAction.value.filter(action => action !== 1);
        this.selectedAlertAction.setValue(updatedActions);
      }
      this.alertCheck = this.selectedAlertType.value || 0;
      if(this.isAlreadyPublished){
        this.selectedStartDate.disable()
        this.selectedStartTime.disable()
      }
    }

    this.x_post.setValue('الإنذار\n\nاسم المنطقة: \nالمحافظات المتأثرة: \n\nللتفاصيل http://ncm.gov.sa/ar/alert/pages/MapDetail.aspx?AlertId=51607&VId=1.0\n\n#الإنذار_المبكر #طقس_السعودية \n#المركز_الوطني_للأرصاد');

    this.selectedRegion.valueChanges.subscribe(() => {
      this.autofillPost();
    });

    this.selectedGovernates.valueChanges.subscribe(() => {
      this.autofillPost();
    });

    this.selectedAlertType.valueChanges.subscribe(() => {
      this.autofillPost();
    });
  }

  autofillPost() {
    let regionText = '';
    let governoratesText = '';
    let color = '';

    const selectedRegion = this.selectedRegion.value;
    const selectedGovernates = this.selectedGovernates.value;

    if (selectedRegion && this.regions?.length > 0) {
      const region = this.regions.find(region => region.id === selectedRegion);
      if (region) {
        regionText = `#${region.name_ar.replace(' ', '_')}`;
      }
    }

    if (selectedGovernates?.length > 0 && this.governates?.length > 0) {
      selectedGovernates.forEach(govId => {
        const governorate = this.governates.find(gov => gov.id === govId);
        if (governorate) {
          governoratesText += `#${governorate.nameAr.replace(' ', '_')} `;
        }
      });
      governoratesText = governoratesText.trim(); // Remove any trailing space
    }

    if(this.selectedAlertType.value && this.alertTypes?.length > 0){
      color = this.alertTypes.find(type => type.id === this.selectedAlertType.value).nameAr;
    }

    const staticText = '#الإنذار_المبكر #طقس_السعودية \n#المركز_الوطني_للأرصاد';

    const combinedText = `الإنذار ${color || ''}\n\nاسم المنطقة: ${regionText}\nالمحافظات المتأثرة: ${governoratesText}\n\nللتفاصيل http://ncm.gov.sa/ar/alert/pages/MapDetail.aspx?AlertId=51607&VId=1.0\n\n${staticText}`.trim();

    this.x_post.setValue(combinedText);
  }

  getCurrentDate() {
    const currentDate = new Date();
    this.today = currentDate
  }

  check(elementRef: ElementRef | any) {
    const check = elementRef?.el?.nativeElement.querySelector('.p-datepicker-trigger');
    check.click();
  }

  ngAfterViewInit(): void {
    this.fixMap();
    if (this.notificationList) {
      this.onChangeAlertType(this.notificationList?.alertType);
      this.getAlertStatusByAlertType(this.notificationList?.alertType);
      this.onRegionChange(this.notificationList?.regionID);
      const governates = [];
      for (let i = 0; i < this.notificationList?.governorates?.length; i++) {
        governates.push(this.notificationList?.governorates[i].id);
      }
      this.onGovernateChange(governates);
    }
  }


  onStartDateSelect(date: Date, retainValues:boolean = false) {
    const startDate = this.datePipe.transform(date, "MM/dd/yyyy");
    this.selectedStartDate.setValue(startDate);
    if(this.isUpdate){
      if(!retainValues){
        return;
      }
      this.selectedAlertAction.setValue([...this.selectedAlertAction.value,3])
    }
  }
  onEndDateSelect(date: Date, retainValues:boolean = false) {
    const endDate = this.datePipe.transform(date, "MM/dd/yyyy");
    this.selectedEndDate.setValue(endDate);
    if(this.isUpdate){
      if(!retainValues){
        return;
      }
      this.selectedAlertAction.setValue([...this.selectedAlertAction.value,3])
    }
  }
  onStartTimeSelect() {
    if(this.isUpdate){
      this.selectedAlertAction.setValue([...this.selectedAlertAction.value,3])
    }
  }
  onEndTimeSelect() {
    if(this.isUpdate){
      this.selectedAlertAction.setValue([...this.selectedAlertAction.value,3])
    }
  }

  getDataFromParams() {
    const params$ = this.route.queryParams.subscribe((params: { list: string, isUpdate: any,isDraft: any, isAlreadyPublished: any }) => {
      if (params?.list) {
        this.isUpdate = JSON.parse(params?.isUpdate);
        this.isDraft = JSON.parse(params?.isDraft);
        this.isAlreadyPublished = JSON.parse(params?.isAlreadyPublished);
        const list: EWSNotificationModel = JSON.parse(params.list);
        this.setFormValues(list);
      }
      else {
        this.setCurrentDateAndTime()
      }
    })
    this.subscription.add(params$);
  }

  setFormValues(notificationList: EWSNotificationModel) {
    if (notificationList?.file?.length > 0) {
      const lastSlashIndex = notificationList.file.lastIndexOf('/');
      let fileName = notificationList.file.substring(lastSlashIndex + 1);

      fileName = fileName.replace(/^\d*\/?/, '');
      fileName = fileName.replaceAll('%20', ' ');
      fileName = fileName.replaceAll('%28', '(').replaceAll('%29', ')');

      this.fileName = fileName;
    }

    this.base64String = localStorage.getItem('tempFile')

    this.ewsNotificationId.setValue(notificationList.id);
    this.selectedAlertAction.setValue(notificationList?.alertActions.map((action) => action.id));
    this.selectedAlertType.setValue(notificationList?.alertType);
    this.onChangeAlertType(notificationList?.alertType);
    this.selectedAlertStatus.setValue(notificationList?.alertStatusID);
    this.getAlertHazards(notificationList?.alertStatusID)
    const hazardIds = notificationList?.alertHazard.map((hazard) => hazard.id)
    this.selectedAlertHazard.setValue(hazardIds);
    this.message.setValue(notificationList?.message?.messageEn || notificationList?.message);
    this.enableEmailNotification.setValue(notificationList?.enableEmailNotification);
    this.enableSMSNotification.setValue(notificationList?.enableSMSNotification);
    this.enableTwitterNotification.setValue(notificationList?.enableTwitterNotification);
    this.selectedStartTime.setValue(convertTo12HourFormat(this.splitDateOrTimeFromDateAndTimeString(notificationList?.fromDate, 'time')));
    this.selectedEndTime.setValue(convertTo12HourFormat(this.splitDateOrTimeFromDateAndTimeString(notificationList?.toDate, 'time')));
    this.selectedStartDate.setValue(this.splitDateOrTimeFromDateAndTimeString(notificationList?.fromDate, 'date'));
    this.selectedEndDate.setValue(this.splitDateOrTimeFromDateAndTimeString(notificationList?.toDate, 'date'));
    this.selectedRegion.setValue(notificationList?.regionID);
    this.onRegionChange(notificationList?.regionID);
    setTimeout(() => {
      notificationList?.governorates.forEach((gov) => this.onSelectGovernate(gov.id));
      notificationList?.ewsOtherLocations.forEach((oth) => this.selectedOtherLocations = [oth.id]);
    }, 500);
    setTimeout(() => {
      const municipalitiesIds = notificationList?.municipalities.map((munc) => munc.id);
      this.selectedMunicipalities.setValue([...municipalitiesIds]);
    }, 1000);

    function convertTo12HourFormat(time24: string): string {
      const [hours, minutes] = time24.split(':');
      const parsedHours = parseInt(hours, 10);

      const meridiem = parsedHours >= 12 ? 'PM' : 'AM';
      const convertedHours = parsedHours % 12 || 12;

      return `${convertedHours}:${minutes} ${meridiem}`;
    }
  }


  setCurrentDateAndTime() {
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();
    const year = new Date().getFullYear();
    const date = `${month}/${day}/${year}`;
    this.selectedStartDate.setValue(date);

    const hour = new Date().getHours();
    if (hour < 12) {
      var period = 'AM'
    }
    else {
      var period = 'PM';
    }
    const minutes = new Date().getMinutes();
    const time = `${hour}:${minutes} ${period}`;
    this.selectedStartTime.setValue(time);
  }


  splitDateOrTimeFromDateAndTimeString(date: string, condition: 'time' | 'date' | 'period') {
    const splittedDate = date.split(" ");
    if (condition === 'date') {
      return this.getYearMonthDay(splittedDate[0], 'slash', 'day month year');
    }
    else if (condition == 'time') {
      return splittedDate[1];
    }
    else if (condition == 'period') {
      return splittedDate[2];
    }
    else {
      console.error("Something Went Wrong")
    }
  }


  getRegions() {
    this.spinner.show();
    this.alertService.getRegions()
      .then((res: { success: boolean, data: RegionModel[] }) => {
        if (res?.success) {
          this.regions = res.data;
          this.filteredReg = this.regions;
          this.regions.forEach((region) => {
            // this.addRegionLayers(region.name_en, region.id);
          })
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      }).finally(() => {
        this.spinner.hide();
      })
  }

  onSelectGovernate(governateId: number) {
    if (this.selectedGovernates?.value?.length === 0) {
      this.selectedGovernates.setValue([governateId]);
      this.onGovernateChange(this.selectedGovernates.value);
      return;
    }
    const governateIds: number[] = this.selectedGovernates.value || [];
    if (!governateIds?.includes(governateId)) {
      governateIds.push(governateId);
    }
    else {
      const index = governateIds.indexOf(governateId);
      governateIds.splice(index, 1);
      this.removeGovernateLayerByIdFromMap(governateId);
    }
    this.selectedGovernates.setValue(governateIds);
    this.onGovernateChange(governateIds)
  }

  onRegionChange(regionId: number, retainValues: boolean = false, changed: boolean = false) {
    this.selectedGovernates.setValue([])
    this.setGovernorateLayers(this.selectedGovernates.value)
    this.selectedMunicipalities.setValue([])
    this.selectedOtherLocations = []
    this.selectedGovernatesAndMuncipalitiesInfo = []
    if(changed){
      if(this.isUpdate){
        this.selectedAlertAction.setValue([])
      }
      this.selectedEndDate.setValue(null)
      this.selectedEndTime.setValue(null)
      this.message.setValue(null)
      this.selectedAlertStatus.setValue(null)
      this.selectedAlertHazard.setValue(null)
      this.selectedAlertType.setValue(null)
    }
    this.spinner.show();
    this.selectedRegionName = this.regions?.find((region: RegionModel) => region?.id === regionId);
    this.fillAndUnfillRegionByRegionIdOnMap(regionId);
    this.alertService.getGovernatesByRegionId(regionId)
      .then((res: { success: boolean, data: GovernateModel[] }) => {
        if (res?.success) {
          this.governates = res.data;
          this.filteredGov = this.governates
          this.allGovernatesIds = []
          setTimeout(() => {
            this.allGovernatesIds = this.governates.map(group => group.id);
          }, 500);
          if (this.governates.length > 0) {
            this.unFillAllGovernatesLayerOnMap();
            this.governates.forEach((gov) => {
              // this.addGovernatesLayers(gov.nameEn, gov.id);
            })
          }
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
    this.alertService.getOtherMunicipalitiesByRegionId(regionId.toString())
      .then((res: { success: boolean, data: Muncipality[] }) => {
        if (res?.success) {
          this.OtherMunicipalities = res.data;
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
    if(this.isUpdate){
      if(!retainValues){
        return;
      }
      this.selectedAlertAction.setValue([...this.selectedAlertAction.value,4])
    }
  }

  removeGovernateLayerByIdFromMap(governateId: number) {
    for (let layer of this.allGovernatesLayers) {
      if (layer?.options?.['govId'] === governateId) {
        layer.setStyle({
          'fillOpacity': 0,
          'weight': 2
        });
        break;
      }
    }
  }

  onGovernateChange(governateIds: number[], retainValues: boolean = false) {
    this.spinner.show();
    this.alertService.getMunicipalityByGovernatesId(governateIds)
      .then((res: { success: boolean; data: MunicipalityModel[] }) => {
        this.municipalities = res?.data || [];
        if (this.selectedGovernatesAndMuncipalitiesInfo.length > 0) {
          governateIds = governateIds.filter(govId => govId !== undefined);

          const validGovernorateIds = new Set(governateIds);
          let hasNewGovernorateId = false;

          for (const info of validGovernorateIds) {
            if (!this.selectedGovernatesAndMuncipalitiesInfo.some(item => item.id === info)) {
                hasNewGovernorateId = true;
                break;
            }
          }

          this.groupMuncipalitiesByGovernates();

          const remainingGovernates = this.selectedGovernatesAndMuncipalitiesInfo.filter(info =>
              validGovernorateIds.has(info.id)
          );

          const remainingMunicipalities = new Set();
          for (const gov of remainingGovernates) {
              for (const municipality of gov.muncipalities) {
                  remainingMunicipalities.add(municipality.id);
              }
          }

          this.selectedMunicipalities.setValue(this.selectedMunicipalities.value.filter(municipality =>
              remainingMunicipalities.has(municipality)
          ))

          this.selectedGovernatesAndMuncipalitiesInfo = remainingGovernates;
        } else {
          this.groupMuncipalitiesByGovernates();
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })

    if(this.isUpdate){
      if(!retainValues){
        return;
      }
      this.selectedAlertAction.setValue([...this.selectedAlertAction.value,4])
    }

    this.setGovernorateLayers(this.selectedGovernates.value);
  }

  setGovernorateLayers(selectedGovs: number[]){
    if (selectedGovs.length > 0) {
      const govs = this.allGov.filter((gov) => selectedGovs.includes(gov.id))

      this.map.setPaintProperty('ksa-govs-layer', 'fill-color', [
        'match',
        ['get', 'REGION_N_1'],
        ...govs.flatMap((gov) => [
          gov.nameEn,
          this.getFillColor(this.selectedAlertType.value),
        ]),
        '#9DCB91',
      ]);
    } else {
      if(this.map){
        this.map.setPaintProperty('ksa-govs-layer', 'fill-color', '#9DCB91');
      }
    }
  }

  getFillColor(color: number) {
    if(color){
      const colorsMap = {
        2: '#FFFF54',
        3: '#F2A93B',
        4: '#D54040',
      };
      color = colorsMap[color]
      return color;
    }

    return '#fff';
  }

  onLocChange(){
    if(this.isUpdate){
      this.selectedAlertAction.setValue([...this.selectedAlertAction.value,4])
    }
  }

  updateFilteredItems(filtered: any[], section: string) {
    if(section === 'Munc'){
      this.filteredGroupedMuncipalities = filtered;
    } else if (section === 'Gov') {
      this.filteredGov = filtered;
    } else if (section === 'Reg') {
      this.filteredReg = filtered;
    } else if (section === 'Status') {
      this.filteredStatus = filtered;
    } else if (section === 'UG') {
      this.filterlistUserGroups = filtered;
    }
  }

  groupMuncipalitiesByGovernates(){
    const groupedMuncipalities = {};
    this.municipalities.forEach((munc) => {
      const governate = munc.governate;

      if (!groupedMuncipalities[governate]) {
        groupedMuncipalities[governate] = {...this.getGovernateById(munc.governate), data: [] };
      }
      const isDuplicate = groupedMuncipalities[governate].data.some(
        (existingMunc) => existingMunc.id === munc.id
      );

      if (!isDuplicate) {
        groupedMuncipalities[governate].data = [
          ...groupedMuncipalities[governate].data,
          munc,
        ];
      }
    });

    this.groupedMuncipalities =  Object.values(groupedMuncipalities).map((value)=> value) as GroupedGovernateModel[];
    this.filteredGroupedMuncipalities = this.groupedMuncipalities;

    this.allMunicipalityIds = []
    this.allMunicipalityIds = this.groupedMuncipalities.flatMap(group => group.data).map(municipality => municipality.id);

    if (this.notificationList && this.municipalities.length > 0) {
      if(this.selectedMunicipalities?.value?.length > 0){
        this.getMuncipalitiesInfo(this.selectedMunicipalities.value);
      }
    } else {
      if(this.municipalities.length > 0){
        this.getMuncipalitiesInfo(this.selectedMunicipalities.value);
      }
    }
  }

  getGovernateById(governateId:number){
    return this.governates.find((governate)=> governate.id === governateId);
  }

  getAlertActions() {
    this.spinner.show();
    this.alertService.getAlertActions()
      .then((res: { success: boolean, data: AlertActionModel[] }) => {
        if (res?.success) {
          this.alertActions = res.data;
          if (this.selectedAlertAction.value.length == 0) {
            this.selectedAlertAction.setValue([this.alertActions[0].id]);
            if (!this.isUpdate) {
              this.selectedAlertAction.disable();
            }
          }
          if(this.isUpdate){
            if(this.isDraft){
              this.selectedAlertAction.setValue([this.alertActions[0].id]);
              this.selectedAlertAction.disable();
            }
            else{
              if(this.selectedAlertAction.value[0] == this.alertActions[0].id){
                this.selectedAlertAction.setValue([])
              }
              this.alertActions.splice(0, 1)
            }
          }
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  getAlertType() {
    this.spinner.show();
    this.alertService.getAlertTypes()
      .then((res: { success: boolean, data: AlertTypeModel[] }) => {
        if (res?.success) {
          this.alertTypes = res.data;
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  onChangeAlertType(alert_id: number, retainValues:boolean = false) {
    this.getAlertStatusByAlertType(alert_id);

    if(retainValues){
      this.selectedAlertHazard.setValue(null)
      this.alertHazards = [];
      this.allHazardIds = [];
    }

    setTimeout(() => {
      this.setGovernorateLayers(this.selectedGovernates.value);
    }, 500);

    if(!retainValues){
      return;
    } else {
      this.alertHazards = [];
      this.allHazardIds = [];
      this.selectedAlertStatus.setValue(null)
      this.selectedAlertHazard.setValue(null)

      if(this.isUpdate){
        const updatedActions = this.selectedAlertAction.value.filter(action => action !== 2 && action !== 6);
        this.selectedAlertAction.setValue(updatedActions);

        const newAction = alert_id > this.alertCheck ? 2 : 6;
        this.selectedAlertAction.setValue([...this.selectedAlertAction.value,newAction])
      }
    }
  }

  getAlertStatusByAlertType(alert_id: number) {
    this.spinner.show();
    this.alertService.getAlertStatusByAlertType([alert_id])
      .then((res: { success: boolean; data: AlertStatusModel[] }) => {
        if (res?.success) {
          this.alertStatus = res.data;
          this.filteredStatus = this.alertStatus;
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  // getAlertHazards() {
  //   this.spinner.show();
  //   this.alertService.getAlertHazards()
  //     .then((res: { success: boolean; data: AlertHazardModel[] }) => {
  //       if (res?.success) {
  //         this.alertHazards = res.data;
  //       }
  //     })
  //     .catch((error) => {
  //       this.globalService.handleError(error);
  //     })
  //     .finally(() => {
  //       this.spinner.hide();
  //     })
  // }

  getAlertHazards(status_id: number, retainValues:boolean = false) {
    this.spinner.show();

    if(retainValues){
      this.selectedAlertHazard.setValue(null)
      this.alertHazards = [];
      this.allHazardIds = [];
    }

    this.alertService.getAlertHazardsByAlertStatus(status_id)
      .then((res: { success: boolean; data: AlertHazardModel[] }) => {
        if (res?.success) {
          this.alertHazards = res.data;
          this.allHazardIds = this.alertHazards.map(hazards => hazards.id);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })

    if(!retainValues){
      return;
    } else {
      if(this.isUpdate){
        this.selectedAlertHazard.setValue(null)
        this.selectedAlertAction.setValue([...this.selectedAlertAction.value,7])
      }
    }
  }

  handleFileChange(event: any): void {
    this.spinner.show();
    const file: File = event.target.files[0];

    if (file) {
      const fileName = file.name;
      const parts = fileName.split('.');
      const filetype = parts.length > 1 ? parts.pop().toLowerCase() : '';

      if (filetype !== 'pdf' && filetype !== 'png' && filetype !== 'jpg') {
        this.toast.error(this.regionPage?.SelectValidFileFormat);
        this.spinner.hide();
      } else {
        if (file.size <= 10 * 1024 * 1024) {
          this.fileName = fileName;
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64String = reader.result as string;
            this.spinner.hide();
          };
          reader.readAsDataURL(file);
        } else {
          this.spinner.hide();
          this.toast.error(this.regionPage?.MaximumFileSize);
        }
      }
    }
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.value = ''; // Clear the file input to allow selecting the same file
    this.fileInput.nativeElement.click();
  }


  fillAndUnfillRegionByRegionIdOnMap(regionId: number) {
    this.regionLayers?.eachLayer((layer) => {
      Object.values(layer._map._layers).forEach((layer: any) => {
        if (layer?.defaultOptions?.regionName) {
          layer.setStyle({
            'fillOpacity': 0,
            'weight': 2
          });
        }
      })
    });
    this.regionLayers?.eachLayer((layer) => {
      Object.values(layer._map._layers).forEach((layer: any) => {
        if (layer?.defaultOptions?.id === regionId) {
          layer.setStyle({
            'fillOpacity': 0.6,
            'weight': 4
          });
        }
      })
    });
  }

  unFillAllGovernatesLayerOnMap() {
    this.governoratesLayers?.eachLayer((layer) => {
      if (layer && layer?._map && layer?._map?.layers) {
        if (Object?.values(layer?._map?._layers)?.length !== 0) {
          Object.values(layer?._map?._layers).forEach((layer: any) => {
            if (layer?.defaultOptions?.govName) {
              layer?.setStyle({
                'fillOpacity': 0,
                'weight': 2
              });
            }
          })
        }
      }
    });
  }

  fillGovernatesByRegionIdOnMap(govId: number, color: string = "#00a3e2") {

    this.governoratesLayers?.eachLayer((layer) => {
      Object.values(layer?._map?._layers).forEach((layer: any) => {
        if (layer?.defaultOptions?.govId === govId) {
          layer?.setStyle({
            'weight': 2,
            'fillOpacity': 0.75,
            'color': color
          });
        }
      })
    })
  }


  // async addGovernatesLayers(govName: string, govId: number) {
  //   try {
  //     const dataGov: any = await this.http.get(`../../../../assets/geojson/Governorates/${govName}.geojson`).toPromise();
  //     if (dataGov) {
  //       this.governoratesLayers = new L.geoJSON(dataGov,
  //         {
  //           'govId': govId || 0,
  //           'govName': govName,
  //           'pane': 'gov',
  //           'color': "#00a3e2",
  //           'weight': 2,
  //           'fillOpacity': 0,
  //         }).addTo(this.map);
  //     }

  //     this.governoratesLayers.on('click', (e: any) => {
  //       if (!this.selectedGovernates.value) {
  //         this.selectedGovernates.setValue([e.layer.defaultOptions.govId]);
  //         this.onGovernateChange(this.selectedGovernates.value);
  //         e.layer.setStyle({
  //           'weight': 4,
  //           'fillOpacity': 0.75,
  //         });
  //       }
  //       else if (this.selectedGovernates.value.includes(e.layer.defaultOptions.govId)) {
  //         const index = this.selectedGovernates.value.indexOf(e.layer.defaultOptions.govId);
  //         this.selectedGovernates.value.splice(index, 1);
  //         this.selectedGovernates.setValue(this.selectedGovernates.value);
  //         e.layer.setStyle({
  //           'fillOpacity': 0,
  //           'weight': 2
  //         });
  //       }
  //       else {
  //         this.selectedGovernates.setValue([...this.selectedGovernates.value, e.layer.defaultOptions.govId])
  //         e.layer.setStyle({
  //           'weight': 4,
  //           'fillOpacity': 0.75
  //         });
  //         this.onGovernateChange(this.selectedGovernates.value);
  //       }
  //     })

  //     this.allGovernatesLayers.push(this.governoratesLayers);
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }

  // }

  // async addRegionLayers(regionName: string, id: number) {
  //   const dataRegion: any = await this.http.get(`../../../../assets/geojson/${regionName}.geojson`).toPromise();
  //   this.regionLayers = new L.geoJSON(dataRegion,
  //     {
  //       id: id,
  //       regionName: regionName,
  //       pane: 'region',
  //       color: "#0b87b752",
  //       weight: 2,
  //       fill: true,
  //       fillOpacity: 0,
  //     }).addTo(this.map);

  //   this.regionLayers.on('click', (e: any) => {
  //     this.regionLayers?.eachLayer((layer) => {
  //       if (layer && layer?._map?._layers) {
  //         Object.values(layer._map._layers).forEach((layer: any) => {
  //           if (layer && layer?.defaultOptions?.regionName) {
  //             layer.setStyle({
  //               'weight': 1,
  //               'fillOpacity': 0,
  //             });
  //           }
  //         })
  //       }
  //     });

  //     if (this.governoratesLayers) {
  //       this.governoratesLayers?.eachLayer((layer) => {
  //         if (layer && layer?._map?._layers) {
  //           Object.values(layer._map._layers).forEach((layer: any) => {
  //             if (layer?.defaultOptions?.govName) {
  //               this.map.removeLayer(layer);
  //             }
  //           })
  //         }
  //       })
  //     }

  //     if (!this.selectedRegion.value) {
  //       this.selectedGovernates.setValue(null);
  //       this.selectedRegion.setValue(e.layer.defaultOptions.id)
  //       e.layer.setStyle({
  //         weight: 4,
  //         fillOpacity: .1,
  //       });
  //       this.onRegionChange(this.selectedRegion.value);
  //       this.map.flyTo(e.latlng, 6, { animate: false });
  //     }
  //     else {
  //       if (this.selectedRegion.value === e.layer.defaultOptions.id) {
  //         this.selectedRegion.setValue(null)
  //         e.layer.setStyle({
  //           weight: 1,
  //           fillOpacity: 0,
  //         });
  //         this.map.zoomOut(5.5)
  //       }
  //       else {
  //         this.selectedRegion.setValue(e.layer.defaultOptions.id)
  //         e.layer.setStyle({
  //           weight: 4,
  //           fillOpacity: 1,
  //         });
  //         this.onRegionChange(this.selectedRegion.value);
  //         this.map.flyTo(e.latlng, 6, { animate: false });
  //       }
  //     }
  //   })
  // }



  getMuncipalitiesInfo(municipalityIds: number[]) {
    if (this.municipalities?.length > 0) {
      const uniqueMuncipalitiesInfo: MunicipalityModel[] = [];
      municipalityIds.forEach((muncId) => {
        uniqueMuncipalitiesInfo.push(this.municipalities.find((munc) => munc.id === muncId));
      });
      this.selectedGovernatesAndMuncipalitiesInfo = this.getClusteredMuncipalities([...new Set(uniqueMuncipalitiesInfo)])
      return true;
    }

    return false;
  }

  getClusteredMuncipalities(muncipalities: MunicipalityModel[]) {
    const governates: GovernateAndMuncipalitiesModel[] = [...this.governates];
    let governatesAndMuncipalities: GovernateAndMuncipalitiesModel[] = [];
    governates.forEach((gov) => {
      gov.muncipalities = [];
      muncipalities.forEach((munc) => {
        if(munc !== undefined && munc !== null){
          if (munc.governate === gov.id) {
            gov.muncipalities.push(munc);
          }
        }
      });

      if (gov.muncipalities.length > 0) {
        governatesAndMuncipalities.push({ ...gov, muncipalities: [...gov.muncipalities] });
      }
    });
    return governatesAndMuncipalities;
  }

  onfly(){
    this.GotoPreview(true)
  }

  onSave(published: boolean, routeToDraft: boolean = true, xpostimg: string = null) {
    this.spinner.show()
    if(published){
      if (!this.isFormValid()) {
        this.spinner.hide();
        return;
      }
    }

    const ewsNotificationModel: EWSNotificationModel = new EWSNotificationModel();
    if (this.ewsNotificationId.value) {
      ewsNotificationModel.id = this.ewsNotificationId?.value || null;
    }
    ewsNotificationModel.regionId = this.selectedRegion?.value ? `${this.selectedRegion.value}` : null;
    ewsNotificationModel.alertStatusId = this.selectedAlertStatus?.value ? this.selectedAlertStatus.value.toString() : null;
    ewsNotificationModel.alertTypeId = this.selectedAlertType?.value ? `${this.selectedAlertType.value}` : null;
    ewsNotificationModel.message = {
      en: this.message?.value || null,
      ar: ""
    };
    ewsNotificationModel.x_post = this.x_post?.value || null;
    ewsNotificationModel.x_img = xpostimg || null;
    ewsNotificationModel.weatherPhenomenonId = this.selectedAlertStatus?.value || null;
    const uniqueValues = [...new Set(this.selectedAlertAction?.value || [])];
    ewsNotificationModel.alertActionId = uniqueValues.length ? uniqueValues.toString() : null;
    ewsNotificationModel.alertHazardId = this.selectedAlertHazard?.value ? this.selectedAlertHazard.value.toString() : null;

    if (this.selectedGovernates?.value?.length > 0) {
      ewsNotificationModel.governateIds = this.selectedGovernates?.value?.length
        ? (this.selectedGovernates.value[0] === undefined
            ? this.selectedGovernates.value.slice(1)?.toString()
            : this.selectedGovernates.value?.toString())
        : null;
    }

    if (this?.selectedMunicipalities?.value?.length > 0) {
      ewsNotificationModel.municipalityIds = this.selectedMunicipalities?.value?.length
        ? (this.selectedMunicipalities.value[0] === undefined
            ? this.selectedMunicipalities.value.slice(1)?.toString()
            : this.selectedMunicipalities.value?.toString())
        : null;
    }

    if (this?.selectedOtherLocations?.length > 0) {
      ewsNotificationModel.ewsOtherLocationIds = this.selectedOtherLocations?.length ? this.selectedOtherLocations?.toString() : null;
    }

    ewsNotificationModel.enableEmailNotification = this.enableEmailNotification?.value || false;
    ewsNotificationModel.enableSMSNotification = this.enableSMSNotification?.value || false;
    ewsNotificationModel.enableTwitterNotification = this.enableTwitterNotification?.value || false;
    ewsNotificationModel.startDate = this.selectedStartDate?.value ? this.getYearMonthDay(this.selectedStartDate.value, "dash", 'year month day') : null;
    ewsNotificationModel.endDate = this.selectedEndDate?.value ? this.getYearMonthDay(this.selectedEndDate.value, "dash", 'year month day') : null;
    ewsNotificationModel.startTime = this.selectedStartTime?.value ? this.convertTimeTo24HourFormat(this.selectedStartTime.value) : null;
    ewsNotificationModel.endTime = this.selectedEndTime?.value ? this.convertTimeTo24HourFormat(this.selectedEndTime.value) : null;

    // Perform start date validation only if start date is not null
    if (ewsNotificationModel.startDate && !this.isStartDateValid(ewsNotificationModel.startDate)) {
      if (!this.isUpdate) {
        this.toast.error("Start Date can't be less than Current Date");
        this.spinner.hide();
        return;
      }
    }

    // Perform end date validation only if all end date and time values are not null
    if (ewsNotificationModel.startDate && ewsNotificationModel.startTime && ewsNotificationModel.endDate && ewsNotificationModel.endTime &&
        !this.isEndDateValid(
          ewsNotificationModel.startDate,
          ewsNotificationModel.startTime,
          ewsNotificationModel.endDate,
          ewsNotificationModel.endTime
        )) {
      this.toast.error("End Date Cannot be less than Start Date");
      this.spinner.hide();
      return;
    }

    if (this.base64String && this.fileName) {
      ewsNotificationModel.file = this.base64String || null;
      ewsNotificationModel.filename = this.fileName || null;
    }

    if (!this.isUpdate) {
      this.createEwsNotification(ewsNotificationModel, published, routeToDraft);
    }
    else {
      this.updateEwsNotification(ewsNotificationModel, published, routeToDraft);
    }
  }

  isStartDateValid(startDateStr: string) {
    const today = new Date();
    const startDate = new Date(startDateStr);

    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();

    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    const todayDay = today.getDate();

    if (startYear < todayYear || (startYear === todayYear && startMonth < todayMonth) || (startYear === todayYear && startMonth === todayMonth && startDay < todayDay)) {
      return false;
    }

    return true;
  }

  formatDate(dateStr) {
    const [year, month, day] = dateStr.split('-');
    return `${year}-${this.addLeadingZero(month)}-${this.addLeadingZero(day)}`;
  }

  addLeadingZero(value) {
    return value.toString().padStart(2, '0');
  }

  isEndDateValid(startDate: string, startTime: string, endDate: string, endTime: string): boolean {
    const formattedStartDate = this.formatDate(startDate);
    const formattedEndDate = this.formatDate(endDate);
    const startDateTime = new Date(`${formattedStartDate} ${startTime}`);
    const endDateTime = new Date(`${formattedEndDate} ${endTime}`);

    if (!this.isValid(startDateTime) || !this.isValid(endDateTime)) {
      this.toast.error("invalid")
      return false;
    }

    return isBefore(endDateTime, startDateTime) ? false : true;
  }

  isValid(date: Date): boolean {
    return !isNaN(date.getTime());
  }


  getYearMonthDay(dateStr: string | Date, format: 'dash' | 'slash' = 'dash', timeFormat: 'day month year' | 'year month day' = 'year month day') {
    if (typeof dateStr !== "string") {
      dateStr = this.datePipe.transform(dateStr, 'yyyy-MM-dd');
      return dateStr;
    }

    let [year, month, day] = dateStr.includes("/") ? dateStr.split("/") : dateStr.split("-");//year, month, day

    if (month?.length === 4) {
      let [correctedDay, correctedYear, correctedMonth] = dateStr.includes("/") ? dateStr.split("/") : dateStr.split("-");// month, day, year
      month = correctedMonth;
      day = correctedDay;
      year = correctedYear;
    }


    if (day?.length === 4) {
      let [correctedMonth, correctedDay, correctedYear] = dateStr.includes("/") ? dateStr.split("/") : dateStr.split("-");// month, day, year
      month = correctedMonth;
      day = correctedDay;
      year = correctedYear;
    }

    if (day.length == 1) {
      day = "0" + day;
    }

    if (format == 'slash') {
      if (timeFormat == 'year month day') {
        return `${year}/${month}/${day}`;
      }
      else {
        return `${month}/${day}/${year}`;
      }
    }
    else {
      if (timeFormat == 'year month day') {
        return `${year}-${month}-${day}`;
      }
      else {
        return `${day}-${month}-${year}`;
      }
    }
  }

  isFormValid() {
    if (!this.selectedRegion?.value) {
      this.toast.error(this.language === 'en' ? 'Region is required' : 'مطلوب المنطقة');
      return false;
    }
    if (this.selectedAlertAction?.value?.length === 0) {
      this.toast.error(this.language === 'en' ? 'Alert Action is required' : 'مطلوب إجراء التنبيه');
      return false;
    }
    if (!this.selectedAlertType?.value) {
      this.toast.error(this.language === 'en' ? 'Alert Type is required' : 'مطلوب نوع التنبيه');
      return false;
    }
    if (!this.selectedAlertStatus?.value) {
      this.toast.error(this.language === 'en' ? 'Alert Status is required' : 'مطلوب حالة التنبيه');
      return false;
    }
    if (!this.selectedAlertHazard?.value) {
      this.toast.error(this.language === 'en' ? 'Alert Hazard is required' : 'مطلوب خطر التنبيه');
      return false;
    }
    if (!this.selectedStartDate?.value) {
      this.toast.error(this.language === 'en' ? 'Start Date is required' : 'مطلوب تاريخ البدء');
      return false;
    }
    if (!this.selectedEndDate?.value) {
      this.toast.error(this.language === 'en' ? 'End Date is required' : 'مطلوب تاريخ الانتهاء');
      return false;
    }
    if (!this.selectedStartTime?.value) {
      this.toast.error(this.language === 'en' ? 'Start Time is required' : 'مطلوب وقت البدء');
      return false;
    }
    if (!this.selectedEndTime?.value) {
      this.toast.error(this.language === 'en' ? 'End Time is required' : 'مطلوب وقت الانتهاء');
      return false;
    }

    return true;
  }

  publishEwsNotification(publish: { id: number, published: boolean, user_group_ids?: number[]}, message: string) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.show();
    }, 500);
    this.alertService.publishEwsNotification(publish)
      .then((res: { success: boolean; data: any }) => {
        if (res.success) {
          this.toast.success(message)
          this.router.navigateByUrl('home/alert-history');
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  createEwsNotification(ewsNotificationModel: EWSNotificationModel, publish: boolean, routeToDraft: boolean = true) {
    this.selectedEmailGroups = this.selectedEmailGroups.filter((group) => group !== undefined);

    this.alertService.createEwsNotification(ewsNotificationModel)
      .then((res: { success: boolean, message: string, notification_id: number }) => {
        if (res?.success) {
          this.saved = true;
          if (publish) {
            if(this.selectedEmailGroups?.length > 0){
              let published: { id: number, published: true, user_group_ids: number[] } = {
                id: res.notification_id,
                published: true,
                user_group_ids: this.selectedEmailGroups
              }
              this.publishEwsNotification(published, res?.message);
            } else {
              let published: { id: number, published: true, } = {
                id: res.notification_id,
                published: true
              }
              this.publishEwsNotification(published, res?.message);
            }
          } else {
            this.toast.success(res?.message);
            if(routeToDraft){
              this.router.navigateByUrl('/home/alert-editor/draft')
            }
          }
        }
        else {
          this.toast.error(res?.message);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  updateEwsNotification(ewsNotificationModel: EWSNotificationModel, publish: boolean, routeToDraft: boolean = true) {
    this.selectedEmailGroups = this.selectedEmailGroups.filter((group) => group !== undefined);

    ewsNotificationModel.is_published = this.isAlreadyPublished
    ewsNotificationModel.user_group_ids = this.selectedEmailGroups

    this.alertService.updateEwsNotification(ewsNotificationModel)
      .then((res: { success: boolean, message: string, notification_id: number }) => {
        if (res?.success) {
          this.saved = true;
          if(publish) {
            if (this.isAlreadyPublished) {
              this.toast.success(res?.message);
              this.router.navigateByUrl('home/alert-history');
            } else {
              if (res?.notification_id) {
                if(this.selectedEmailGroups?.length > 0){
                  let published: { id: number, published: true, user_group_ids: number[] } = {
                    id: res.notification_id,
                    published: true,
                    user_group_ids: this.selectedEmailGroups
                  }
                  this.publishEwsNotification(published, res?.message);
                } else {
                  let published: { id: number, published: true, } = {
                    id: res.notification_id,
                    published: true
                  }
                  this.publishEwsNotification(published, res?.message);
                }
              }
            }
          } else {
            this.toast.success(res?.message);
            if(routeToDraft){
              this.router.navigateByUrl('/home/alert-editor/draft')
            }
          }
        } else {
          this.toast.error(res?.message);
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  modalSave(){
    if(this.selectedEmailGroups.length === 0){
      this.enableEmailGroups = false;
    }
  }
  modalClose(){
    if(this.selectedEmailGroups.length === 0){
      this.enableEmailGroups = false;
    }
  }

  goToDraft() {
    this.router.navigateByUrl('/home/alert-editor/draft');
  }

  GotoPreview(save: boolean = false) {
    this.viewPreview = false;
    this.workPreview = false;
    this.navigationExtras = null

    if (!this.isFormValid()) {
      return;
    }

    const listEwsNotificationModel: ListEwsNotificationModel = new ListEwsNotificationModel();
    listEwsNotificationModel.startDate = this.getYearMonthDay(this.selectedStartDate?.value);
    listEwsNotificationModel.endDate = this.getYearMonthDay(this.selectedEndDate?.value);
    listEwsNotificationModel.startTime = this.convertTimeTo24HourFormat(this.selectedStartTime?.value);
    listEwsNotificationModel.endTime = this.convertTimeTo24HourFormat(this.selectedEndTime?.value);

    if (!this.isStartDateValid(listEwsNotificationModel.startDate)) {
      if(!this.isUpdate){
        this.toast.error("Start Date can't be less than Current Date");
        this.spinner.hide();
        return;
      }
    }
    if (!this.isEndDateValid(listEwsNotificationModel.startDate, listEwsNotificationModel.startTime, listEwsNotificationModel.endDate, listEwsNotificationModel.endTime)) {
      this.toast.error("End Date Cannot be less than Start Date");
      this.spinner.hide()
      return;
    }

    listEwsNotificationModel.regionID = this.selectedRegion.value;
    this.alertTypes.some((type) => {
      if (this.selectedAlertType.value === type.id) {
        listEwsNotificationModel.alertTypeEn = type.color.toLowerCase();
        listEwsNotificationModel.alertTypeAr = type.nameAr;
        this.spinner.hide()
        return true;
      }
    })

    const alertActions = [];
    this.alertActions.map((action) => {
      if (this.selectedAlertAction.value.some((selectedActionId) => selectedActionId == action.id)) {
        alertActions.push({
          id: action.id,
          descriptionEn: action.nameEn,
          descriptionAr: action.nameAr
        });
      }
    });
    listEwsNotificationModel.alertActions = alertActions;

    const alertHazard = [];
    this.alertHazards.map((hazard) => {
      if (this.selectedAlertHazard.value.some((selectedHazardId) => selectedHazardId == hazard.id)) {
        alertHazard.push({
          id: hazard.id,
          nameEn: hazard.nameEn,
          nameAr: hazard.nameAr
        })
      }
    });
    listEwsNotificationModel.alertHazard = alertHazard;

    this.alertStatus.some((status) => {
      if (this.selectedAlertStatus.value === status.id) {
        listEwsNotificationModel.alertStatusEn = status.nameEn;
        listEwsNotificationModel.alertStatusAr = status.nameAr;
        this.spinner.hide()
        return true;
      }
    })

    listEwsNotificationModel.id = this.ewsNotificationId.value;
    listEwsNotificationModel.alertStatusID = this.selectedAlertStatus?.value;
    listEwsNotificationModel.alertType = this.selectedAlertType?.value;
    listEwsNotificationModel.enableEmailNotification = this.enableEmailNotification.value || false;
    listEwsNotificationModel.enableSMSNotification = this.enableSMSNotification.value || false;
    listEwsNotificationModel.enableTwitterNotification = this.enableTwitterNotification.value || false;
    listEwsNotificationModel.message = this.message.value;
    this.selectedRegionName = this.regions?.find((region: RegionModel) => region?.id === this.selectedRegion.value);
    listEwsNotificationModel.regions = this.selectedRegionName;
    listEwsNotificationModel.regionID = this.selectedRegion.value;
    listEwsNotificationModel.governateIds = this.selectedGovernates.value.toString();
    listEwsNotificationModel.municipalityIds = this.selectedMunicipalities.value.toString();
    if (this?.selectedOtherLocations?.length > 0) {
      listEwsNotificationModel.ewsOtherLocationIds = this?.selectedOtherLocations?.toString();
    }
    const governates: any[] = [];
    this.governates.forEach((governate) => {
      if (this.selectedGovernates.value.includes(governate.id)) {
        governates.push(governate);
      }
    })
    listEwsNotificationModel.governorates = governates;
    const municipalities: any[] = [];
    this.municipalities.forEach((munc) => {
      if (this.selectedMunicipalities.value.includes(munc.id)) {
        municipalities.push(munc);
      }
    })
    listEwsNotificationModel.municipalities = municipalities;
    const otherMunicipalities: any[] = [];
    this.OtherMunicipalities.forEach((munc) => {
      if (this.selectedOtherLocations.includes(munc.id)) {
        otherMunicipalities.push(munc);
      }
    })
    listEwsNotificationModel.ewsOtherLocations = otherMunicipalities;

    if (this.fileName) {
      const parts = this.fileName.split('.');
      const filetype = parts.length > 1 ? parts.pop().toLowerCase() : '';

      if (filetype !== 'pdf' && filetype !== 'png' && filetype !== 'jpg') {
        this.toast.error(this.regionPage?.SelectValidFileFormat);
        this.spinner.hide()
        return;
      }
    }
    if (this.base64String && this.fileName) {
      listEwsNotificationModel.filename = this.fileName
      localStorage.setItem("tempFile", this.base64String)
    }
    this.spinner.show()
    setTimeout(() => {
      this.navigationExtras = {
        queryParams: {
          list: JSON.stringify(listEwsNotificationModel),
          isUpdate: this.isUpdate,
          isAlreadyPublished: this.isAlreadyPublished
        }
      }
      this.viewPreview = !save;
      this.workPreview = true;
      this.spinner.hide()
      setTimeout(() => {
        if(this.preview){
          this.preview.runSave = save;
        }
      }, 500);
    }, 500);
  }

  fixMap() {
    if(this.map){
      this.map.remove();
    }
    this.pointOfInterest = false;
    this.initializeMap = true;
    setTimeout(() => {
      this.map?.resize();
    }, 100)
    this.initMap();
  }


  // initMap() {
  //   this.map = null;
  //   this.isUpsertModal = false;
  //   this.map = new Map(this.mapContainer?.nativeElement, this.options);
  //   this.featureGroup = new FeatureGroup();
  //   this.map.addLayer(this.featureGroup);



  //   this.drawOptions = {
  //     circlemarker: false,
  //     rectangle: false,
  //     circle: false,
  //     marker: false,
  //     polygon: false,
  //     polyline: false,
  //   };
  //   this.drawOptions = {};


  //   this.map.createPane('basemap').style.zIndex = '400';
  //   this.map.createPane('region').style.zIndex = '500';
  //   this.map.createPane('gov').style.zIndex = '600';


  //   this.map.on('click', (e: L.LeafletMouseEvent) => {

  //     if (this.currentMarker) {
  //       this.featureGroup.removeLayer(this.currentMarker);
  //     }

  //     const lat = e.latlng.lat;
  //     const lng = e.latlng.lng;
  //     this.coordinates = this.coordinates || [[lat, lng]];
  //     let layers: any[] = [];

  //     layers = this.featureGroup.getLayers();
  //     layers.forEach((layer) => {
  //       this.featureGroup.removeLayer(layer);
  //     })
  //     this.featureGroup.addLayer(this.currentMarker);

  //     const geocoder = new google.maps.Geocoder();
  //     const latlng = new google.maps.LatLng(lat, lng);
  //     geocoder.geocode({ location: latlng }, (results: any, status: string) => {
  //       if (status === "OK" && results[0]) {
  //         const location = results[0];
  //         let locality = "";
  //         let sublocality = "";
  //         let political = "";

  //         for (let i = 0; i < location.address_components.length; i++) {
  //           const component = location.address_components[i];
  //           if (component.types.includes("political")) {
  //             political = component.long_name;
  //           }
  //           if (component.types.includes("sublocality")) {
  //             sublocality = component.long_name;
  //           }
  //           if (component.types.includes("locality")) {
  //             locality = component.long_name;
  //           }
  //         }

  //         this.name = sublocality || locality || political || location.formatted_address;
  //         this.address_components = location.address_components;

  //         const markerLatLng = this.currentMarker.getLatLng();
  //         const markerLat = markerLatLng.lat;
  //         const markerLng = markerLatLng.lng;

  //         this.coordinates = [[markerLat, markerLng]];
  //       }
  //     });

  //   });
  // }

  async initMap() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer?.nativeElement,
      style: 'mapbox://styles/owaisnadeem786/clyfvebbt00t601qpfg5aau9q',
      zoom: 4,
      fitBoundsOptions: { padding: 20 },
        maxBounds: [
          [25.0, 7.0],
          [62.0, 43.0]
      ],
      maxZoom: 14,
      minZoom: 4,
      center: [45.0792, 23.8859],
      dragRotate: false,
      pitchWithRotate:false,
      accessToken: environment.mapboxAccessToken,
    });

    this.map.setProjection('mercator')

    this.map.touchZoomRotate.disableRotation();
    this.map.dragRotate.disable();
    this.map.setMaxPitch(0);
    this.map.setMinPitch(0);

    this.map.on('zoom', () => {
      this.updateLabels();
    });

    this.map.on('style.load', ()=>{
      this.alertService.getGovernatesByRegionId()
      .then((res:{sucess:boolean; data:GetGovernoratesModel[]})=>{
        this.allGov = res?.data
        this.getAndSetGovernoratesLayersOnMap(this.allGov)
      })
      .catch((error)=>{
        this.globalService.handleError(error);
      })
      setTimeout(() => {
        this.map.resize();
      }, 500);
    })
  }

  async getAndSetGovernoratesLayersOnMap(governorates: GetGovernoratesModel[]) {
    // Promise.all(governorates.map(gov => this.addGovernotateLayerOnMap(gov, this.weatherWarning.data)))
    //   .then(() => {
    //     // console.log('alert',this.weatherWarning?.data);
    //     // console.log('allGov',this.allGov);
    //     governorates.forEach(governorate => this.setWarningsOfGovernorates(governorate));
    //   });
      try {
          const alertsDataMap: { [key: string]: any } = {};

          const govResult = await fetch('/assets/geojson/ksa_governorate_boundaries.geojson');
          const govGeojsonData = await govResult.json();

          const govGeojsonOptions = {
              type: 'geojson' as const,
              data: govGeojsonData,
              promoteId: 'id',
          };

          this.map.addSource('ksa-govs', govGeojsonOptions);
          this.map.addLayer({
              id: 'ksa-govs-layer',
              type: 'fill',
              source: 'ksa-govs',
              paint: {
                  'fill-color': '#9DCB91',
                  'fill-opacity': 1,
                  'fill-outline-color': '#4C4C4C',
              },
          });

          this.addKsaRegionsGeojson();
          this.addGeoJsonMarkerOnMap(governorates);
          this.getRegionsAndAddMarkerToMap();

          setTimeout(() => {
            this.setGovernorateLayers(this.selectedGovernates.value);
          }, 500);
      } catch (error) {
          console.log(error);
      }
  }

  async addKsaRegionsGeojson() {
    const regionGeojsonData = await this.http
      .get(`/assets/geojson/ksa_regions.geojson`)
      .toPromise();

    if (regionGeojsonData) {
      const regionGeojsonOptions: any = {
        type: 'geojson',
        data: regionGeojsonData,
        promoteId: 'id',
      };

      this.map.addSource('ksa-regions', regionGeojsonOptions);

      this.map.addLayer({
        id: 'ksa-regions-layer',
        type: 'fill',
        source: 'ksa-regions',
        paint: {
          'fill-color': '#000',
          'fill-opacity': 0,
        },
      });

      this.map.addLayer({
        id: 'ksa-regions-layer-border',
        type: 'line',
        source: 'ksa-regions',
        paint: {
          'line-color': 'rgba(76, 76, 76, 0.6)',
          'line-width': 2,
        },
      });
    }
  }

  async addGeoJsonMarkerOnMap(governoratesResponse: GetGovernoratesModel[]) {
    const features = governoratesResponse.map((gov) => {
      const name = this.language === 'en' ? gov.nameEn : gov.nameAr;
      return {
        type: 'Feature',
        properties: { name },
        geometry: {
          type: 'Point',
          coordinates: [gov.longitude, gov.latitude],
        },
      };
    });

    const geojson = {
      type: 'FeatureCollection',
      features: features,
    };

    this.map.addSource('gov-markers-source', {
      type: 'geojson',
      data: geojson,
    } as any);

    this.map.addLayer({
      id: 'gov-markers-layer',
      type: 'symbol',
      source: 'gov-markers-source',
      layout: {
        'icon-image': 'icons/gov-marker-icon',
        'icon-size': 1,
        'text-field': ['get', 'name'],
        // 'text-font': ['Open Sans Regular'],
        'text-size': 12,
        'text-offset': [0, 1],
        'text-anchor': 'top',
      },
    });

    this.map.setLayoutProperty('gov-markers-layer', 'visibility', 'none');
  }

  async getRegionsAndAddMarkerToMap() {
    const result = await this.alertService.getRegions() as ResponseModel<RegionModel[]>;
    const regions = result.data;
    if (regions?.length > 0) {
      const geojsonData = {
        type: 'FeatureCollection',
        features: regions.map((region) => (
           {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [region.longitude, region.latitude],
          },
          properties: {
            name: this.language === 'en' ? region.name_en : region.name_ar,
            className: 'mapboxgl-region-marker',
          },
        })),
      };

      this.addMarkersFromGeoJSON(geojsonData);
    }
  }

  async addMarkersFromGeoJSON(geojsonData: any) {
    const features = geojsonData.features.map((feature) => {
      const { name } = feature.properties;
      return {
        type: 'Feature',
        properties: { name },
        geometry: {
          type: 'Point',
          coordinates: feature.geometry.coordinates,
        },
      };
    });

    const geojson: any = {
      type: 'FeatureCollection',
      features: features,
    };

    this.map.addSource('custom-markers-source', {
      type: 'geojson',
      data: geojson,
    });

    this.map.addLayer({
      id: 'custom-markers-layer',
      type: 'symbol',
      source: 'custom-markers-source',
      layout: {
        'icon-size': 1,
        'text-field': ['get', 'name'],
        // 'text-font': ['Open Sans Regular'],
        'text-size': 15,
        'text-offset': [2, -1],
        'text-anchor': 'top',
      },
    });

    this.map.setLayoutProperty('custom-markers-layer', 'visibility', 'visible');

  }

  updateLabels() {
    const currentZoom = parseInt(this.map.getZoom()?.toString());
    if(currentZoom > 1 && currentZoom < 5.5){
      this.map.setLayoutProperty('custom-markers-layer', 'visibility', 'visible');
    }
    else{
      this.map.setLayoutProperty('custom-markers-layer', 'visibility', 'none');

    }

    if (currentZoom <= 5.5) {
      this.map.setLayoutProperty('gov-markers-layer', 'visibility', 'none');
    } else {
      this.map.setLayoutProperty('gov-markers-layer', 'visibility', 'visible');
    }
  }

  removeMunicipalities(muncId: number) {
    this.spinner.show();
    const selectedMunc = this.selectedMunicipalities.value;
    const index = this.selectedMunicipalities.value.indexOf(muncId);
    selectedMunc.splice(index, 1);
    this.selectedMunicipalities.setValue(selectedMunc);
    if (this.selectedMunicipalities.value?.length === 0) {
      this.selectedGovernatesAndMuncipalitiesInfo = []
      this.spinner.hide();
      return
    }
    this.selectedGovernatesAndMuncipalitiesInfo = this.selectedGovernatesAndMuncipalitiesInfo.filter((munc) => munc.id !== muncId);
    this.spinner.hide();
  }


  convertTimeTo24HourFormat(timeString: string): string | null {
    if (!timeString) {
      return null;
    }

    const [time, period] = timeString.split(" ");

    if (!time || !period) {
      return null;
    }

    const seconds = '00';


    let [hours, minutes] = time.split(":");

    if (minutes.length == 1) {
      minutes = "0" + minutes;
    }


    if (parseInt(hours) > 12) {
      return `${hours}:${minutes}:${seconds}`;
    }

    const convertedHours = parseInt(hours, 10);

    if (isNaN(convertedHours) || minutes.length !== 2 || parseInt(minutes, 10) > 59) {
      return null;
    }

    if (period.toLowerCase() === 'pm' && convertedHours !== 12) {
      hours = String(convertedHours + 12);
    } else if (period.toLowerCase() === 'am' && convertedHours === 12) {
      hours = '00';
    }

    return `${hours.padStart(2, '0')}:${minutes}:${seconds}`;
  }


  async setDelay(func, ms: number, ...args) {
    let status;
    setTimeout(async () => {
      status = await func(...args);
      if (!status) {
        this.setDelay(func, ms, ...args)
      }
    }, ms)
  }

  openModalForEmails(event:any, email: TemplateRef<any>){
    if(event){
      this.openEmailModal(email);
    }else{
      return;
    }
  }
  openEmailModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered:true, modalDialogClass:'modal-lg', backdrop: 'static' }).result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  checkALLGroups(){
    if(this.enableEmailGroups) {
      const allUGids = this.listUserGroups.map((ug) => ug.id)
      this.selectedEmailGroups = allUGids;
    } else {
      this.selectedEmailGroups = []
    }
  }

  getUserGroupsForDropdown(clienttype?:any, page?:any){
    const listUserGroupModelAPI: ListUserGroupModelAPI = new ListUserGroupModelAPI();
    if(page){
      listUserGroupModelAPI.page = page;
    }
    else{
      if(this.ListPagination?.pageCount > (this.currentPageForUserGroups + 1 )){
        this.currentPageForUserGroups++;
        listUserGroupModelAPI.page = this.currentPageForUserGroups;
      } else{
        return;
      }
    }
    listUserGroupModelAPI.type = 'ews';
    this.spinner.show();
    this.userGroupService.listUserGroup(listUserGroupModelAPI).then(
      (res:{success:boolean,data:CreateUserGroupModelAPI[],paginationVariables:PaginationVariables}) => {
        if(res.success){
          if(this.listUserGroups?.length > 0){
            this.listUserGroups = [ ...this.listUserGroups, ...res.data];
          }
          else{
            this.listUserGroups = res.data;
          }
          this.filterlistUserGroups = this.listUserGroups
          this.allUGIds = []
          setTimeout(() => {
            this.allUGIds = this.listUserGroups.map(group => group.id);
          }, 500);
          this.ListPagination = res.paginationVariables;
          this.checkALLGroups()
        }
      }
    ).catch((err) => {
      this.globalService.handleError(err);
    })
    .finally(()=>{
      this.spinner.hide();
    })
  }

  hasUnsavedChanges(): boolean {
    if(this.saved || this.isUpdate){
      return false;
    } else {
      if (this.selectedRegion.value !== null){
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
