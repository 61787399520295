  <!-- <div class="page-titlealert">
    <i class="fas fa-caret-right"></i>
    <p class="active">{{ automaticpreviewPage?.AutomaticWeatherReports }}</p>
  </div> -->
  <!-- <div class="locationBG justify-content-between">
    <div class="addLocHeading">
      <img class="headerIcon" src="../../../assets/automaticweatherreport/automaticweatherreport-icon.png" />
      <h3>{{ automaticpreviewPage?.AutomaticWeatherReports }}</h3>
    </div>
  </div> -->
  <p-toolbar class="row dome-a" style="margin: inherit;">
    <div class="p-toolbar-group-left col justify-content-between">
      <div class="d-flex gap-2 align-items-center">
        <img src="../../../assets/automaticweatherreport/automaticweatherreport-icon.png" style="scale: 0.7;">
        <div class="alert-history">{{ automaticpreviewPage?.AutomaticWeatherReports }}</div>
      </div>
    </div>
  </p-toolbar>
  <div class="previewTablediv my-30">
    <div class="previewTable">
      <div class="tableheading row py-3 mx-5 align-items-center brdr-bottom">
        <h3 class="col-lg-7 col-md-6 col-sm-6 col-6 p-0">{{ language == 'en' ? titleEn : titleAr }}</h3>
        <div class="col-lg-5 col-md-6 col-sm-6 col-6 px-0 row gap-3 d-flex justify-content-end">
          <!-- <button class="widget-btn text-white btn-primary-dark col-lg-6 col-md-6 col-sm-12">
            <span class="plus-icon"><img src="../../../../assets/automaticweatherreport/downloadreport-icon.png"></span>
             {{ automaticpreviewPage?.DownloadAllCitiesReport }}
           </button> -->
        </div>
      </div>
      <div class="accordion py-3 px-3vw" id="accordionExample">
        <div *ngFor="let reportdata of ENData; index as i" class="accordion-item py-1">
          <h2 [id]="'heading'+i" class="accordion-header">
            <button class="accordion-button collapsed d-flex justify-content-center" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" aria-controls="collapseOne">
              {{language == 'en' ? reportdata?.cityEn : reportdata?.cityAr}}
              <!-- {{language == 'en' ? reportdata?.city.en : reportdata?.city.ar}} -->
            </button>
          </h2>
          <div [id]="'collapse'+i" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="mb-3 d-flex justify-content-end">
                <button class="widget-btn col-lg-6 col-md-6 col-sm-12" type="button"  (click)="openPreviewModal(reportdata,i)">{{ automaticpreviewPage?.EditReport }}</button>
              </div>
              <div class="d-flex flex-wrap previewcontainer"> <!-- 24 HRS BOXES STARTED-->
                <div *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].tmax]" class="previewbody px-3 col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12">
                  <h5 class="parameterstext previewbodybrdr py-3">{{language == 'en' ? 'Max Temperature (°C)' : 'درجة الحرارة القصوى (°م)'}}</h5>
                  <div class="d-flex">
                    <img class="col-4" src="../../../../assets/automaticweatherreport/temperature.png">
                    <div class="col-6 d-flex flex-column justify-content-center">
                      <h5 class="parameterstext my-1">{{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].tmax]?.[0]?.value }}°</h5>
                    </div>
                  </div>
                </div>
                <div *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].windspeed]" class="previewbody px-3 col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12">
                  <h5 class="parameterstext previewbodybrdr py-3">{{language == 'en' ? 'Wind Speed (km/h) - Day' : 'سرعة الرياح (كم/ساعة) - نهارًا'}}</h5>
                  <div class="d-flex">
                    <img class="col-4" src="../../../../assets/automaticweatherreport/windspeed.png">
                    <div class="col-6 d-flex flex-column justify-content-center">
                      <h5 class="parameterstext my-1">{{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].windspeed]?.[0]?.value }}{{ automaticpreviewPage?.kmhr }}</h5>
                    </div>
                  </div>
                </div>
                <div *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].winddirection]" class="previewbody px-3 col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12">
                  <h5 class="parameterstext previewbodybrdr py-3">{{language == 'en' ? 'Wind Direction - Day' : 'اتجاه الرياح - يوم'}}</h5>
                  <div class="d-flex">
                    <img class="col-4" src="../../../../assets/automaticweatherreport/winddirection.png">
                    <div class="col-6 d-flex flex-column justify-content-center">
                      <h5 class="parameterstext my-1">{{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].winddirection]?.[0]?.value | windDirectionCriteria }}</h5>
                    </div>
                  </div>
                </div>
                <div *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].weathersymbol]" class="previewbody px-3 col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12">
                  <h5 class="parameterstext previewbodybrdr py-3">{{language == 'en' ? 'Weather Condition - Day' : 'حالة الطقس - اليوم'}}</h5>
                  <div class="d-flex">
                    <img class="col-4" [src]="'../../../../assets/weather-icons/'+ ENData[i]?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[0]?.value +'.svg'">
                    <div class="col-6 d-flex flex-column justify-content-center">
                      <h5 class="parameterstext my-1" *ngIf="language == 'en'">{{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[0]?.value | weatherDescription }}</h5>
                      <h5 class="parameterstext my-1" *ngIf="language == 'ar'">{{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[0]?.value | weatherDescriptionAR }}</h5>
                    </div>
                  </div>
                </div>
                <div *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].firsthightide]" class="previewbody px-3 col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12">
                  <h5 class="parameterstext previewbodybrdr py-3">{{language == 'en' ? 'High Tide' : 'مرتفع المد'}}</h5>
                  <div class="d-flex">
                    <img class="col-4" src="../../../../assets/automaticweatherreport/highlowtide.png">
                    <div class="col-6 d-flex flex-column justify-content-center w-100">
                      <h5 class="parameterstext my-1">{{ language == 'en' ? 'First' : 'أولاً' }} - {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].firsthightide]?.[0]?.value | HighLowTideCriteria }}</h5>
                      <h5 class="parameterstext my-1">{{ language == 'en' ? 'Second' : 'ثانية' }} - {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].secondhightide]?.[0]?.value | HighLowTideCriteria }}</h5>
                    </div>
                  </div>
                </div>
              </div> <!-- 24 HRS BOXES ENDED-->

              <!-- <div   class="addLocHeading d-flex flex-wrap w-100 py-5">
                <h5 [ngClass]="{'d-none': reporttypekey === 'red-sea-report' || reporttypekey === 'arabian-gulf-report'}" class="titletext w-100">{{ automaticpreviewPage?.ReportTitle }}</h5>
                <label [ngClass]="{'d-none': reporttypekey === 'red-sea-report' || reporttypekey === 'arabian-gulf-report'}" class="titleinput pt-4 w-100 px-3">{{Report_Title}}</label>
              </div> -->

              <div *ngIf="reporttypekey != 'custom-weather-report' && reporttypekey != 'mashaer-weather-report'" class="d-flex flex-wrap g-3 brdr-bottom-grey py-5">
                <div class="dist d-flex flex-column gap-3" style="padding-inline-end: 1rem;">
                  <div class="detail-en">
                    <h5 class="titletext">{{ automaticpreviewPage?.SurfaceWind }} <span>( English )</span></h5>
                    <div class="col-md-12 d-flex justify-content-center">
                      <!-- <label class="textareaclass">{{Surface_Wind}}</label> -->
                      <textarea [(ngModel)]="Surface_Wind" id="summary" (onkeyup)="validate()" class="textareaclass"
                        [placeholder]="automaticpreviewPage?.EnterSurfaceWind"></textarea>
                    </div>
                  </div>
                  <div class="detail-ar">
                    <h5 class="titletext">{{ automaticpreviewPage?.SurfaceWind }} <span style="font-family: 'Somar' !important;font-size: 18px !important;">( عربي )</span></h5>
                    <div class="col-md-12 d-flex justify-content-center">
                      <!-- <label class="textareaclass">{{Surface_Wind_ar}}</label> -->
                      <textarea [(ngModel)]="Surface_Wind_ar" id="summary" (onkeyup)="validate()" class="textareaclass"
                        [placeholder]="automaticpreviewPage?.EnterSurfaceWind"></textarea>
                    </div>
                  </div>
                </div>
                <div class="dist d-flex flex-column gap-3" style="padding-inline-end: 1rem;">
                  <div class="detail-en">
                    <h5 class="titletext">{{ automaticpreviewPage?.WaveHeight }} <span>( English )</span></h5>
                    <div class="col-md-12 d-flex justify-content-center">
                      <!-- <label class="textareaclass">{{Wave_Height}}</label> -->
                      <textarea [(ngModel)]="Wave_Height" id="summary" (onkeyup)="validate()" class="textareaclass"
                        [placeholder]="automaticpreviewPage?.EnterWaveHeight"></textarea>
                    </div>
                  </div>
                  <div class="detail-ar">
                    <h5 class="titletext">{{ automaticpreviewPage?.WaveHeight }} <span style="font-family: 'Somar' !important;font-size: 18px !important;">( عربي )</span></h5>
                    <div class="col-md-12 d-flex justify-content-center">
                      <!-- <label class="textareaclass">{{Wave_Height_ar}}</label> -->
                      <textarea [(ngModel)]="Wave_Height_ar" id="summary" (onkeyup)="validate()" class="textareaclass"
                        [placeholder]="automaticpreviewPage?.EnterWaveHeight"></textarea>
                    </div>
                  </div>
                </div>
                <div class="dist d-flex flex-column gap-3">
                  <div class="detail-en">
                    <h5 class="titletext">{{ automaticpreviewPage?.StateoftheSea }} <span>( English )</span></h5>
                    <div class="col-md-12 d-flex justify-content-center">
                      <!-- <label class="textareaclass">{{State_of_the_Sea}}</label> -->
                      <textarea [(ngModel)]="State_of_the_Sea" id="summary" (onkeyup)="validate()" class="textareaclass"
                        [placeholder]="automaticpreviewPage?.EnterStateoftheSea"></textarea>
                    </div>
                  </div>
                  <div class="detail-ar">
                    <h5 class="titletext">{{ automaticpreviewPage?.StateoftheSea }} <span style="font-family: 'Somar' !important;font-size: 18px !important;">( عربي )</span></h5>
                    <div class="col-md-12 d-flex justify-content-center">
                      <!-- <label class="textareaclass">{{State_of_the_Sea_ar}}</label> -->
                      <textarea [(ngModel)]="State_of_the_Sea_ar" id="summary" (onkeyup)="validate()" class="textareaclass"
                        [placeholder]="automaticpreviewPage?.EnterStateoftheSea"></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-5 pb-4 brdr-bottom-grey"> <!-- 12 HRS TABLE STARTED-->
                <h5 class="titletext">{{ automaticpreviewPage?.TheExpected }} {{ language == 'en' ? descriptionEn : descriptionAr }}</h5>
                <div class="table-responsive" style="overflow: auto;">
                  <table class="autoTable">
                    <thead>
                      <tr>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].tmax]" [ngClass]="language == 'en' ? 'padding-en' : 'padding-ar'">
                          {{language == 'en' ? 'Temperature' : 'درجة حرارة'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].winddirection]">
                          {{language == 'en' ? 'Wind Direction' : 'اتجاه الرياح'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].windspeed]">
                          {{language == 'en' ? 'Wind Speed' : 'سرعة الرياح'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].waveheight]">
                          {{language == 'en' ? 'Max Individual Wave Height' : 'أقصى ارتفاع للأمواج الفردية'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].pressure]">
                          {{language == 'en' ? 'Pressure' : 'الضغط'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].visibility]">
                          {{language == 'en' ? 'Visibility' : 'الرؤية'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].humidity]">
                          {{language == 'en' ? 'Relative Humidity' : 'نسبة رطوبة نسبية'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].firsthightide]">
                          {{language == 'en' ? 'High Tide' : 'مرتفع المد'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].firstlowtide]">
                          {{language == 'en' ? 'Low Tide' : 'منخفض المد'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].precipitation]">
                          {{language == 'en' ? 'Precipitation' : 'تساقط'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].probability]">
                          {{language == 'en' ? 'Probability' : 'احتمالا'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].slipperyroad]">
                          {{language == 'en' ? 'Slippery Road' : 'طريق منزلق'}}
                        </th>
                        <th *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].weathersymbol]">
                          {{language == 'en' ? 'Weather Condition' : 'حالة الطقس'}}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].tmax]" [ngClass]="language == 'en' ? 'padding-en' : 'padding-ar'">
                          <b>{{automaticpreviewPage.Maximum}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].tmax]?.[0]?.value }}{{ automaticpreviewPage?.Cc }}<br><br>
                          <b>{{automaticpreviewPage.Minimum}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].tmin]?.[0]?.value }}{{ automaticpreviewPage?.Cc }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].winddirection]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].winddirection]?.[0]?.value | windDirectionCriteria }}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].winddirection]?.[1]?.value | windDirectionCriteria }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].windspeed]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].windspeed]?.[0]?.value }}{{ automaticpreviewPage?.kmhr }}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].windspeed]?.[1]?.value }}{{ automaticpreviewPage?.kmhr }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].waveheight]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].waveheight]?.[0]?.value | HighLowTideCriteria}}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].waveheight]?.[1]?.value | HighLowTideCriteria}}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].pressure]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].pressure]?.[0]?.value }} {{ automaticpreviewPage?.hPa }}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].pressure]?.[1]?.value }} {{ automaticpreviewPage?.hPa }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].visibility]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].visibility]?.[0]?.value | VisibilityCriteria }}{{ automaticpreviewPage?.km }}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].visibility]?.[1]?.value | VisibilityCriteria }}{{ automaticpreviewPage?.km }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].humidity]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].humidity]?.[0]?.value }}%<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].humidity]?.[1]?.value }}%
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].firsthightide]">
                          <b>{{automaticpreviewPage.TheFirst}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].firsthightide]?.[0]?.value | HighLowTideCriteria }}<br><br>
                          <b>{{automaticpreviewPage.TheSecond}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].secondhightide]?.[0]?.value | HighLowTideCriteria }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].firstlowtide]">
                          <b>{{automaticpreviewPage.TheFirst}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].firstlowtide]?.[0]?.value | HighLowTideCriteria }}<br><br>
                          <b>{{automaticpreviewPage.TheSecond}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].secondlowtide]?.[0]?.value | HighLowTideCriteria }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].precipitation]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].precipitation]?.[0]?.value }}{{ automaticpreviewPage?.mm }}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].precipitation]?.[1]?.value }}{{ automaticpreviewPage?.mm }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].probability]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].probability]?.[0]?.value }}%<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].probability]?.[1]?.value }}%
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].slipperyroad]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].slipperyroad]?.[0]?.value | slipperyCriteria: language }}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ ENData[i]?.parameters[reportTypeMapping[reporttypekey].slipperyroad]?.[1]?.value | slipperyCriteria: language }}
                        </td>
                        <td *ngIf="reportdata?.parameters[reportTypeMapping[reporttypekey].weathersymbol]">
                          <b>{{automaticpreviewPage.DuringtheDay}}</b><br>
                          {{ language == 'en' ? (ENData[i]?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[0]?.value | weatherDescription) : (ENData[i]?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[0]?.value | weatherDescriptionAR) }}<br><br>
                          <b>{{automaticpreviewPage.DuringtheNight}}</b><br>
                          {{ language == 'en' ? (ENData[i]?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[1]?.value | weatherDescription) : (ENData[i]?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[1]?.value | weatherDescriptionAR) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> <!-- 12 HRS TABLE ENDED-->
              <!-- <div [id]="'chart-container'+i" class="chart"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end align-items-center flex-wrap gap-2">
    <!-- <button type="button" class="canceltxt">
      {{ automaticpreviewPage?.Cancel }}
    </button> -->
    <button type="button" class="publishbtn" (click)="goToPreview()">
      {{ automaticpreviewPage?.Back }}
    </button>
    <!-- <button *ngIf="reporttypeid == 9109" (click)="organizationarray.length > 0 || organizationcheck == true ? publishReport() : " type="button" class="previewbtn">
      {{ automaticpreviewPage?.Publish }}
    </button> -->
    <button (click)="publishReport()" type="button" class="previewbtn">
      {{ automaticpreviewPage?.Publish }}
    </button>
  </div>

  <!-- <ng-template #content let-modal>
    <div class="modal-header">
      <h3 class="modalheadtext m-0" id="modal-basic-title">{{ automaticpreviewPage?.SelectAnOrganization }}</h3>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="d-flex align-items-center gap-2 px-3">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"  class="custom-dropdown sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12 w-50" appearance="outline">
          <mat-label>{{ automaticpreviewPage?.SelectAnOrganization }}</mat-label>
          <mat-select [(ngModel)]="organizationarray" multiple msInfiniteScroll (infiniteScroll)="getOrganizationUsersForDropdown()" floatLabel="always" [disabled]="organizationcheck">
            <ng-container *ngFor="let group of organizationUsers.data">
              <mat-option [value]="group.id">{{ language == 'en' ? group?.nameEn : group?.nameAr }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="selectAll" checked [(ngModel)]="organizationcheck">
          <label for="selectAll" class="form-check-label">{{ automaticpreviewPage?.SelectAllOrganizations }}</label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="previewbtn" (click)="modal.close('Save click')">{{ automaticpreviewPage?.Save }}</button>
    </div>
  </ng-template> -->

  <!-- <button class="btn btn-lg btn-outline-primary" (click)="">Launch demo modal</button> -->

  <!-- (onSelect)="applyFiltersAndPagination()"
  (onDeSelect)="applyFiltersAndPagination()"
  (onSelectAll)="applyFiltersAndPagination()"
  (onDeSelectAll)="applyFiltersAndPagination()" -->
