import {
  ALERTEDITOR_PAGE_ROUTE,
  ALERTHISTORY_PAGE_ROUTE,
  ADD_CLIENTS,
  ADD_USERS, PROFILE,
  ALERTDRAFTS_PAGE_ROUTE,
  MANNED_FORECAST_PAGE_ROUTE,
  AUTOMATIC_WEATHER_REPORT_PAGE_ROUTE,
  ARCHIVED_PAGE_ROUTE,
  PACKAGES,
  PERMISSIONS,
  USER_GROUP_PAGE_ROUTE,
  EWS_LIST_WARNING_ROUTE,
} from 'src/app/constants/constants';

export interface MenuItem {
  name_en: string;
  name_ar: string;
  iconName?: string;
  path?: string;
  adminPages?: boolean;
  subItems?: MenuItem[];
  route?: string;
  permissions?:string[];
}

export const MENU_ITEMS: MenuItem[] = [
  {
    name_en: 'Early Warning System',
    name_ar: 'نظام الإنذار المبكر',
    iconName: 'ews',
    path: ALERTHISTORY_PAGE_ROUTE,
    permissions:[PERMISSIONS.EARLY_WARNING],
    subItems: [
      // {
      //   name_en: 'Early Warning System',
      //   name_ar: 'نظام الإنذار المبكر',
      //   path: ALERTHISTORY_PAGE_ROUTE,
      // },
      {
        name_en: 'Archived Alerts',
        name_ar: 'التحذيرات المؤرشفة',
        path: ARCHIVED_PAGE_ROUTE,
      },
      {
        name_en: 'Alert Drafts',
        name_ar: 'مسودة الإنذارات',
        path: ALERTDRAFTS_PAGE_ROUTE,
      }
    ]
  },
  {
    name_en: 'Test Early Warning',
    name_ar: 'اختبار الإنذار المبكر',
    iconName: 'announcement',
    path: EWS_LIST_WARNING_ROUTE,
    permissions:[PERMISSIONS.EARLY_WARNING]
  },
  // {
  //   name_en: 'Manned-Forecast',
  //   name_ar: 'التوقعات مأهولة',
  //   iconName: 'mannedforecast',
  //   path: MANNED_FORECAST_PAGE_ROUTE,
  //   permissions:[PERMISSIONS.MANNED_FORECAST]
  // },
  {
    name_en: 'External Users',
    name_ar: 'المستخدمين الخارجيين',
    iconName: 'external-users',
    permissions:[PERMISSIONS.CLIENTS || PERMISSIONS.PACKAGE],
    subItems: [
      {
        name_en: 'Clients',
        name_ar: 'العملاء',
        path: ADD_CLIENTS,
        permissions:[PERMISSIONS.CLIENTS],
      },
      {
        name_en: 'Packages',
        name_ar: 'الباقات',
        iconName: 'users',
        path: PACKAGES,
        permissions:[PERMISSIONS.PACKAGE]
      }
    ]
  },
  {
    name_en: 'Internal Users',
    name_ar: 'المستخدمين الداخليين',
    iconName: 'internal-users',
    path: ADD_USERS,
    permissions:[PERMISSIONS.USERS]
  },
  // {
  //   name_en: 'Profile',
  //   name_ar: 'الملف الشخصي',
  //   iconName: 'profile',
  //   path: PROFILE,
  // },
  // {
  //   name_en: 'Packages',
  //   name_ar: 'الباقات',
  //   iconName: 'users',
  //   path: PACKAGES,
  //   permissions:[PERMISSIONS.PACKAGE]
  // },
  {
    name_en: 'Weather Report',
    name_ar: 'تقرير الطقس التلقائي',
    iconName: 'weather-reports',
    path: AUTOMATIC_WEATHER_REPORT_PAGE_ROUTE,
    permissions:[PERMISSIONS.REPORT]
  },
  {
    name_en: 'Alert Groups',
    name_ar: 'مجموعات الإشعار',
    iconName: 'alert-groups',
    path: USER_GROUP_PAGE_ROUTE,
    permissions:[PERMISSIONS.REPORT || PERMISSIONS.EARLY_WARNING]
  },
  // {
  //   name_en: 'Alert Editor',
  //   name_ar: 'محرر التنبيه',
  //   iconName: 'alert',
  //   path: ALERTEDITOR_PAGE_ROUTE,
  // },
];

