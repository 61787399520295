import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { icon, marker } from 'leaflet';
import * as esriVector from "esri-leaflet-vector";
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading = false;
  title = 'ncm-frontend';
  paradise = marker([46.78465227596462, -121.74141269177198], {
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: 'leaflet/marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  });

  constructor(private translate: TranslateService, private router: Router) {
    
    // @ts-ignore
    esriVector.setRTLTextPlugin('https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.js');
    mapboxgl.setRTLTextPlugin('https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.js', null);

    let language = localStorage.getItem('language');

    if (language) {
      if (language === 'en') {
        document.body.classList.add('english-font');
        document.body.setAttribute('dir', 'ltr');
      }
      else {
        document.body.classList.add('arabic-font');
        document.body.setAttribute('dir', 'rtl');

      }
      this.translate.use(language);
    }
    else {
      document.body.classList.add('arabic-font');
      document.body.setAttribute('dir', 'rtl');
      localStorage.setItem('language', 'ar');
      this.translate.use('ar');
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;

      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        setTimeout(() => {
          this.isLoading = false;
        }, 2000)
      }
    });
  }
}

