import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginModel, OtpModel } from 'src/app/models/login/login.model';
import { ColorModeService } from 'src/app/services/color-mode/color-mode.service';
import { AuthenticationService } from '../service/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { GlobalService, UserInfo } from 'src/app/shared/utilities/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  @ViewChildren('firstInput, secondInput, thirdInput, fourthInput') inputFields: QueryList<ElementRef>;
  @ViewChild('content') content: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  language:string;
  loginForm: FormGroup;
  login: any;
  isLanguageChecked: boolean = true;
  isColorChecked: boolean;
  submitted: boolean = false;
  currentlanguage: string;
  currentColorMode: string | null;
  // private subscription: Subscription = new Subscription();
  permissions: any;
  permission: any;
  otpForm: FormGroup;
  email:any;
  isOtp:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private colorModeService: ColorModeService,
    private spinner: NgxSpinnerService,
    private modalSerivce: NgbModal,
    private datePipe: DatePipe,
  ) {
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.subscriptions.add(this.translate.get("Login").subscribe((res) => {
        this.login = res;
      }));
    }));
    this.currentlanguage = localStorage.getItem("language");
    this.isLanguageChecked = this.currentlanguage === "en" ? false : true;

    this.currentColorMode = localStorage.getItem("mode");


    if(this.currentColorMode && this.currentColorMode === 'dark'){
      this.isColorChecked = true
    }
    else{
      this.isColorChecked = false
    }

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        null,
        [Validators.required, Validators.email],
      ],
      password: [null, Validators.required],
    });
    this.otpForm = this.formBuilder.group({
      firstNumber: [null, Validators.pattern('[0-9]')],
      secondNumber: [null, Validators.pattern('[0-9]')],
      thirdNumber: [null, Validators.pattern('[0-9]')],
      fourthNumber: [null, Validators.pattern('[0-9]')],
    });
  }

  onChangeLanguage(checked: boolean) {
    if (checked) {
      this.languageService.changeLanguage("ar");
      document.body.classList.remove("english-font");
      document.body.classList.add("arabic-font");
      document.body.setAttribute("dir", "rtl");
    } else {
      this.languageService.changeLanguage("en");
      document.body.classList.remove("arabic-font");
      document.body.classList.add("english-font");
      document.body.setAttribute("dir", "ltr");
    }
  }
  onColorModeChange(checked: boolean) {
    if (!checked) {
      this.colorModeService.changeColorMode("dark");
      document.body.classList.add('dark');
    } else {
      this.colorModeService.changeColorMode("light");
      document.body.classList.remove('dark');
    }
  }

  get loginFormControls() {
    return this.loginForm.controls;
  }

  getUsers() {
    this.spinner.show();
    this.authenticationService.getUserDetails().toPromise()
    .then((res:{success:boolean; message:string;data:UserInfo}) => {
        this.spinner.hide();
        if (res.success) {
          localStorage.setItem("user", JSON.stringify(res.data));
          this.globalService.userSource.next(res?.data);
          const loginTime = this.datePipe.transform(new Date().toISOString(),'dd/MM/yyyy h:mm a');
          localStorage.setItem('lastLoginTime',loginTime);
        }
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toast.error(err.error);
      }
    );
  }

  determineRedirectPath(permission: any) {
    if (permission?.grants?.search_ews_notification) {
      this.router.navigateByUrl("home/alert-history");
    } else if (permission?.grants?.organization_list_users) {
      this.router.navigateByUrl("home/addclient");
    } else if (permission?.grants?.list_ncm_user) {
      this.router.navigateByUrl("home/addusers");
    } else if (permission?.grants?.list_package) {
      this.router.navigateByUrl("home/packages");
    } else if (permission?.grants?.list_report) {
      this.router.navigateByUrl("home/automatic-weather-report");
    } else {
      this.router.navigateByUrl("home/profile");
    }
  }

  onSubmit() {
    this.submitted = true
    if (!this.loginForm.controls?.['email'].value) {
      this.toast.error(this.login?.emailRequired);
      return;
    }
    if (!this.loginForm.controls?.['email']?.value.includes('@')) {
      this.toast.error(this.login?.emailIncorrect);
      return;
    }

    if (!this.loginForm.controls?.['password'].value) {
      this.toast.error(this.login?.passwordRequired);
      return;
    }

    this.spinner.show();
    const user: LoginModel = new LoginModel();
    user.username = this.loginForm.controls['email'].value.toLowerCase();
    user.password = this.loginFormControls['password'].value;
    this.authenticationService.login(user).then(
      (res: {token?:string, message?:string, email?:string, success?:boolean}) => {
        if(res?.email){
          localStorage.setItem('email', res?.email);
          this.toast.success(res?.message);
          this.otpForm.reset();
          this.openOtpModal(this.content);
        }
        else if (res?.token) {
          localStorage.setItem('token', res?.token)
          this.getAndSetMomraMapToken();
          this.getUsers()
          this.toast.success(this.login?.loggedInSuccessfully);
          this.authenticationService.getUserPermissions().then((data: any) => {
            this.permission = data
            if(this.permission){
              this.globalService.setPermissions(this.permission)
              this.determineRedirectPath(this.permission)
            }
          })
        }
        else{
            this.toast.error(res.message);
        }
    })
    .catch((err: HttpErrorResponse)=>{
      this.toast.error(err.error.message);
    })
    .finally(()=>{
      this.spinner.hide();
    })
  }

  openOtpModal(content: TemplateRef<any>) {
    this.modalSerivce.open(content, {
      backdrop: true,
      centered: true,
    });
  }

  onModalClose(){
    this.otpForm.reset();
  }

  moveToNextField(nextIndex: number): void {
    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.otpinput');
    if (inputs[nextIndex - 1].value.length === 1) {
      if (nextIndex < inputs.length) {
        inputs[nextIndex].focus();
      }
    }
  }
  // moveToNextField(currentIndex: number, event: Event): void {
  //   const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.otpinput');
  //   const currentInput = inputs[currentIndex] as HTMLInputElement;

  //   // Ensure only numeric values are allowed
  //   const inputValue = currentInput.value.trim();
  //   if (!/^[0-9]*$/.test(inputValue)) {
  //     currentInput.value = ''; // Clear invalid input
  //     return;
  //   }

  //   // Move focus to the next field when entering a value
  //   if (inputValue !== '' && currentIndex < inputs.length - 1) {
  //     inputs[currentIndex + 1].focus();
  //   }

  //   // Handle backspace key press
  //   if (event instanceof KeyboardEvent && event.key === 'Backspace') {
  //     const previousInput = inputs[currentIndex - 1] as HTMLInputElement;
  //     previousInput.focus(); // Focus on the previous field
  //     previousInput.value = ''; // Clear content of the previous input field
  //   }
  // }

  // onFourthInput(event: Event): void {
  //   // Trigger onOTPSubmit() function when entering the fourth value
  //   const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.otpinput');
  //   const fourthInput = inputs[3] as HTMLInputElement;
  //   const fourthValue = fourthInput.value.trim();

  //   if (fourthValue !== '') {
  //     this.onOTPSubmit();
  //   }
  // }
  onOTPSubmit(){
    if(this.otpForm.invalid){
      this.toast.error(this.login?.otpRequired);
      return;
    }
    const otp = Object.values(this.otpForm.value).join('');
    this.email = localStorage.getItem('email');
    const user: OtpModel = new OtpModel();
    user.email = this.email;
    user.otp = otp;
    this.authenticationService.otpCheck(user).then(
      (res: {token:string, message:string, success?:boolean}) => {
        if (res?.token) {
          localStorage.setItem('token', res?.token)
          this.getAndSetMomraMapToken();
          this.getUsers()
          this.toast.success(this.login?.loggedInSuccessfully);
          this.authenticationService.getUserPermissions().then((data: any) => {
            this.permission = data
            if(this.permission){
              this.globalService.setPermissions(this.permission)
              this.determineRedirectPath(this.permission)
            }
          })
          this.modalSerivce.dismissAll();
          this.otpForm.reset();
        }
        else{
          this.toast.error(res?.message);
        }
      },
    ).catch((err: HttpErrorResponse)=>{
      this.toast.error(err.error.message);
    })
  }

  getAndSetMomraMapToken(){
    this.authenticationService.getMomraMapToken()
    .then((res:{token:string})=>{
        if(res?.token){
          localStorage.setItem('mapToken', res?.token);
        }
    })
    .catch((error)=>{
      this.globalService.handleError(error);
    })
  }
  showPassword(event:any, id){
    if(event.target.classList.contains('fa-eye')){
      event.target.classList.replace('fa-eye', 'fa-eye-slash');
      document.getElementById(id).setAttribute('type', 'password');
    }
    else{
      event.target.classList.replace('fa-eye-slash', 'fa-eye');
      document.getElementById(id).setAttribute('type', 'text');
    }
  }
}
