export class UsersModel {
  id?:number;
  email: string;
  fullName: string;
  name: string;
  role:string;
  invitingClients: boolean;
  managingClients: boolean;
  deletingClients: boolean;
  invitingNCMAdmin: boolean;
  managingNCMAdmin: boolean;
  deletingNCMAdmin: boolean;
  allowOrganizationAdminToInviteUsers: boolean;
  companyNameEn: string;
  companyNameAR: string;
  logo: string;
}

export class UserExcelModelAPI {
  type: string
}

export class NCMUserModel {
  email: string
  name: string
  role: string
  location: number[]
  id: number;
  // disallowedApiGroups: number[];
  allowedApiGrpups: number[];
  tagId?: number;
  password?: string;
  isNoExpiry?: boolean;
  constructor(){
    this.isNoExpiry = true;
  }
}

export class NCMClientModelAPI {
  search?: string;
  searchByTag?: number;
  organization_name?: string;
  clientType?: string;
  package_name?: string;
  page:number;
  page_size:number;
  organization_id?:number;
  package_id?:number;
  sortByCreated: string;
  sortByStatus: string;

  constructor(){
    this.page_size = 10;
  }
}

export class ListUserTagModelAPI{
  search?: string;
  page:number;
  page_size:number;
}
